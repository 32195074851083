import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
export default class extends Controller {
  static targets = [
    'prefs',
    'loadingIcon',
    'dealsList',
    'prefsform',
    'prefsToggleButton',
    'submit',
    'prefsFormContainer',
    'prefsOptionsContainer',
    'dealsListContainer',
    'toggleContractType'
  ];

  initialize() {

    this.savePrefsPath = this.element.getAttribute('data-save-prefs-path');
    this.resetPrefsPath = this.element.getAttribute('data-reset-prefs-path');
    this.appView = this.element.getAttribute('data-app-view');

    // TODO: separate this out into new controller - slider controls for filters

    this.dealSimOnlyRadio = document.getElementById('sim');

    this.dealCurrentNetworkSelector = document.querySelector(
      '.deal_current_networks_selector ul'
    );

    this.dealNetworksSelector = document.querySelector(
      '.deal_networks_selector ul'
    );

    this.dealHandsetsSelector = document.querySelector(
      '.deal_manufacturers_selector ul'
    );

    this.dealModelSelector = document.querySelector(
      '.deal_product_version_selector ul'
    );

    this.dealCapacityDefaultSelector = document.querySelector(
      '.deal_capacity_selector ul.capacity-list-default'
    );

    this.dealCapacitySelector = document.querySelector(
      '.deal_capacity_selector ul.capacity-list'
    );

    this.dealContractLengthSimOnlySelector = document.querySelector(
      '.deal_contract_lengths_sim_only_selector ul'
    );

    this.dealContractLengthHandsetSelector = document.querySelector(
      '.deal_contract_lengths_handset_selector ul'
    );

    this.dealUsageBytesSelector = document.querySelector(
      '.deal_usage_bytes_selector ul'
    );

    this.dealUpfrontFeeSelector = document.querySelector(
      '.deal_upfront_fee_selector ul'
    );

    // Add event listeners for scroll
    this.initContractTypeSelector();

    const enableDisableChevrons = function (list, item) {
      const leftChevron = list.parentElement.querySelector('.select-left');
      const rightChevron = list.parentElement.querySelector('.select-right');

      const activeChildren = list.querySelectorAll('li:not(.is-hidden)');

      if (leftChevron) {
        leftChevron.disabled = item == activeChildren[0];
      }

      if (rightChevron) {
        const lastChild = activeChildren[activeChildren.length-1];

        if (lastChild.dataset.hackFix === "iOS 15") {
          // -2 to account for the iOS 15 fix element that is not present on
          // other browsers.
          rightChevron.disabled = item == activeChildren[activeChildren.length-2];
        } else {
          rightChevron.disabled = item == activeChildren[activeChildren.length-1];
        }
      }
    }

    const markSelectedItem = function (list, item) {
      Array.from(list.children).forEach(function (child) {
        child.classList.remove('selected');
      });

      if (item) {
        item.classList.add('selected');
      }
    }


    this.dealCurrentNetworkSelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, false, function(item, that) {
        const x = document.querySelector(
          'input[name="deal_preference[current_network]"]'
        );
        x.value = item.dataset.networkId ? parseInt(item.dataset.networkId, 10) : null;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealNetworksSelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, false, function(item) {
        const x = document.querySelector(
          'input[name="deal_preference[deal_network_ids][]"]'
        );
        x.value = item.dataset.networkId ? parseInt(item.dataset.networkId, 10) : null;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealHandsetsSelector.addEventListener('scroll', (ev) => {
      onSliderScroll(ev.currentTarget, false, (item) => {
        const x = document.querySelector(
          'input[name="deal_preference[deal_handset_ids][]"]'
        );
        x.value = item.dataset.manufacturerId
          ? parseInt(item.dataset.manufacturerId, 10)
          : null;

        removeLastVisble('.model.last-visible');
        let products = document.querySelectorAll('.model');

        if (item.dataset.manufacturerId) {
          products.forEach((product) => {
            if (product.dataset.manufacturer === item.dataset.manufacturerRemoteId) {
              product.classList.remove('is-hidden');
            } else {
              product.classList.add('is-hidden');
            }
          });

          const filteredProducts = document.querySelectorAll(
            '.model:not(.is-hidden)'
          );
          if (filteredProducts.length > 0) {
            filteredProducts[filteredProducts.length - 1].classList.add(
              'last-visible'
            );
            this.dealModelSelector.scrollLeft = 0;
          }
        } else {
          products.forEach((product) => {
            product.classList.remove('is-hidden');
          });
        }

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealModelSelector.addEventListener('scroll', (ev) => {
      onSliderScroll(ev.currentTarget, false, (item) => {
        const x = document.querySelector(
          'input[name="deal_preference[deal_product_version_ids][]"]'
        );
        x.value = item.dataset.productId ? parseInt(item.dataset.productId, 10) : null;

        const defaultList = document.querySelector('.capacity-list-default');
        const capacityList = document.querySelector('.capacity-list');

        if (item.dataset.productId) {
          defaultList.classList.add('is-hidden');
          capacityList.classList.remove('is-hidden');

          removeLastVisble('.capacity.last-visible');

          let capacities = document.querySelectorAll(
            '.capacity-list > .capacity'
          );
          capacities.forEach((capacity) => {
            if (capacity.dataset.productId === item.dataset.productId) {
              capacity.classList.remove('is-hidden');
            } else {
              capacity.classList.add('is-hidden');
            }
          });

          const filteredCapacities = document.querySelectorAll(
            '.capacity:not(.is-hidden)'
          );
          if (filteredCapacities.length > 0) {
            filteredCapacities[filteredCapacities.length - 1].classList.add(
              'last-visible'
            );
            this.dealCapacityDefaultSelector.scrollLeft = 0;
            this.dealCapacitySelector.scrollLeft = 0;
          }

          onSliderScroll(capacityList, false, (item) => {
            enableDisableChevrons(capacityList, item);
            markSelectedItem(capacityList, item);
          });
        } else {
          defaultList.classList.remove('is-hidden');
          capacityList.classList.add('is-hidden');

          onSliderScroll(defaultList, false, (item) => {
            enableDisableChevrons(defaultList, item);
            markSelectedItem(defaultList, item);
          });
        }

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealCapacitySelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, true, function(item) {
        const x = document.querySelector(
          'input[name="deal_preference[capacity]"]'
        );
        x.value = item.dataset.capacity ? item.dataset.capacity : null;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealCapacityDefaultSelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, true, function(item) {
        const x = document.querySelector(
          'input[name="deal_preference[capacity]"]'
        );
        x.value = item.dataset.capacity ? item.dataset.capacity : null;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealContractLengthSimOnlySelector.addEventListener(
      'scroll',
      function(ev) {
        onSliderScroll(this, true, function(item) {
          const x = document.querySelector(
            'input[name="deal_preference[contract_lengths_sim_only][]"]'
          );
          x.value = item.dataset.contractLengthsSimOnly
            ? parseInt(item.dataset.contractLengthsSimOnly, 10)
            : null;

          enableDisableChevrons(ev.target, item);
          markSelectedItem(ev.target, item);
        });
      }
    );

    this.dealContractLengthHandsetSelector.addEventListener(
      'scroll',
      function(ev) {
        onSliderScroll(this, true, function(item) {
          const x = document.querySelector(
            'input[name="deal_preference[contract_lengths_handset][]"]'
          );

          x.value = item.dataset.contractLengthsHandset
            ? parseInt(item.dataset.contractLengthsHandset, 10)
            : null;

          enableDisableChevrons(ev.target, item);
          markSelectedItem(ev.target, item);
        });
      }
    );

    this.dealUsageBytesSelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, true, function(item) {
        const x = document.querySelector(
          'input[name="deal_preference[data_usage_bytes]"]'
        );
        x.value = item.dataset.usageAmount ? parseInt(item.dataset.usageAmount, 10) : null;

        const dataOverrideInput = document.querySelector(
          'input[name="deal_preference[override_data_usage]"]'
        );

        dataOverrideInput.value = item.dataset.usageAmount ? true : false;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });

    this.dealUpfrontFeeSelector.addEventListener('scroll', function(ev) {
      onSliderScroll(this, true, function(item) {
        const upfrontFeeInput = document.querySelector(
          'input[name="deal_preference[upfront_fee]"]'
        );

        upfrontFeeInput.value = item.dataset.upfrontFee
          ? parseInt(item.dataset.upfrontFee, 10)
          : null;

        enableDisableChevrons(ev.target, item);
        markSelectedItem(ev.target, item);
      });
    });


    const leftChevrons = Array.from(document.querySelectorAll("button.select-left"));
    const rightChevrons = Array.from(document.querySelectorAll("button.select-right"));

    leftChevrons.forEach(function (button) {
      button.addEventListener("click", function (ev) {
        ev.preventDefault();
        const list = ev.target.closest("div").querySelector("ul:not(.is-hidden)");
        list.scrollLeft -= itemWidthForScroll(list.children[0]);
      });
    });

    rightChevrons.forEach(function (button) {
      button.addEventListener("click", function (ev) {
        ev.preventDefault();
        const list = ev.target.closest("div").querySelector("ul:not(.is-hidden)");
        list.scrollLeft += itemWidthForScroll(list.children[0]);
      });
    });
  }

  connect() {
    this.retrieve();
  }

  buildFetchDataURL() {
    return this.savePrefsPath;
  }

  resetPrefs(ev) {
    ev.preventDefault();
    this.showLoading();

    //Reset all prefs
    this.resetSimOnlyDealsInputs();
    this.resetHandsetDealsInputs();

    console.warn(this.resetPrefsPath, ev);
    let fetchData = {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    fetch(this.resetPrefsPath, fetchData)
      .then((response) => {
        return response.text();
      })
      .catch((err) => {
        return `<h4 class="has-medium-margin-bottom has-medium-margin-top has-h4-text has-center-aligned-text">There was an error resetting the preferences.</h4>`;
      })
      .then((data) => {
        this.hideLoading();
        document.documentElement.scrollTop = 0;
        this.updateList(data);
      });
  }

  togglePrefs() {
    const refreshButton = document.querySelector('.refresh-deal-button');

    if (this.prefsOptionsContainerTarget.classList.contains('is-hidden')) {
      this.showPrefs();
      this.prePopulateFilters();
      refreshButton.classList.remove('is-hidden');
    } else {
      this.hidePrefs();
      refreshButton.classList.add('is-hidden');
      document.documentElement.scrollTop = 0;
    }
  }

  prePopulateFilters() {
    const formBody = new URLSearchParams(new FormData(this.prefsformTarget));
    const initialFontSize = 14;


    // All items are the same size so get the first item of a list that is
    // shared between SIM Only and Phones to only calculate it once.
    const itemWidthPx = itemWidthForScroll(this.dealCurrentNetworkSelector.children[0]);


    // Current Network (O2, EE etc)
    const currentNetworkId = formBody.get('deal_preference[current_network]');
    const dealCurrentNetworkSelectorItems = this.dealCurrentNetworkSelector.querySelectorAll(
      'li'
    );

    const currentNetworkIndex = Array.from(
      dealCurrentNetworkSelectorItems
    ).findIndex((n) => n.dataset.networkId === currentNetworkId);

    this.dealCurrentNetworkSelector.scrollLeft = itemWidthPx * currentNetworkIndex;

    // Preferred Networks (O2, EE etc)
    const networkId = formBody.get('deal_preference[deal_network_ids][]');
    const dealNetworksSelectorItems = this.dealNetworksSelector.querySelectorAll(
      'li'
    );
    const networkIndex = Array.from(dealNetworksSelectorItems).findIndex(
      (n) => n.dataset.networkId === networkId
    );
    this.dealNetworksSelector.scrollLeft = itemWidthPx * networkIndex;

    // Handsets (Apple, Google etc) - needs to be called before model to trigger
    // the scroll listener for the model selector below
    const handsetId = formBody.get('deal_preference[deal_handset_ids][]');
    const dealHandsetsSelectorItems = this.dealHandsetsSelector.querySelectorAll(
      'li'
    );
    const handsetIndex = Array.from(dealHandsetsSelectorItems).findIndex(
      (n) => n.dataset.manufacturerId === handsetId
    );
    this.dealHandsetsSelector.scrollLeft = itemWidthPx * handsetIndex;

    // Model (iPhone X, Pixel 6 etc)
    // Need this to run after handset scroll handler as that sets model scrollLeft to 0 on change
    setTimeout(() => {
      const modelId = formBody.get(
        'deal_preference[deal_product_version_ids][]'
      );
      const dealModelSelectorItems = this.dealModelSelector.querySelectorAll(
        'li:not(.is-hidden)'
      );
      const modelIndex = Array.from(dealModelSelectorItems).findIndex(
        (n) => n.dataset.productId === modelId
      );
      this.dealModelSelector.scrollLeft = itemWidthPx * modelIndex;

      // Capacities (32GB, 64GB etc)
      // Need this whole block including querySelectors to run after model scroll handler
      // as the model scroll handler sets the is-hidden classes on the capacity list items
      setTimeout(() => {
        const capacity = formBody.get('deal_preference[capacity]');
        const capacitySelector = modelId
          ? this.dealCapacitySelector
          : this.dealCapacityDefaultSelector;
        const capacitySelectorItems = capacitySelector.querySelectorAll(
          'li:not(.is-hidden)'
        );
        const capacityIndex = Array.from(capacitySelectorItems).findIndex(
          (n) => n.dataset.capacity === capacity
        );
        capacitySelector.scrollLeft = itemWidthPx * capacityIndex;
      }, 500);
    }, 300);

    // Contract Length for Sim only deals
    const contractLengthId = formBody.get(
      'deal_preference[contract_lengths_sim_only][]'
    );
    const dealContractLengthItems = this.dealContractLengthSimOnlySelector.querySelectorAll(
      'li'
    );
    const contractLengthIndex = Array.from(dealContractLengthItems).findIndex(
      (n) => n.dataset.contractLengthsSimOnly === contractLengthId
    );

    this.dealContractLengthSimOnlySelector.scrollLeft = itemWidthPx * contractLengthIndex;

    //Contract Length for Handset deals
    const contractLengthHandsetId = formBody.get(
      'deal_preference[contract_lengths_handset][]'
    );
    const contractLengthHandsetItems = this.dealContractLengthHandsetSelector.querySelectorAll(
      'li'
    );
    const contractLengthHandsetIndex = Array.from(
      contractLengthHandsetItems
    ).findIndex(
      (n) => n.dataset.contractLengthsHandset === contractLengthHandsetId
    );
    this.dealContractLengthHandsetSelector.scrollLeft = itemWidthPx * contractLengthHandsetIndex;

    // Data Allowance for Sim only deals
    const dataUsageByte = formBody.get('deal_preference[data_usage_bytes]');
    const dealUsageByteItems = this.dealUsageBytesSelector.querySelectorAll(
      'li'
    );
    const dealUsageByteIndex = Array.from(dealUsageByteItems).findIndex(
      (n) => n.dataset.usageAmount === dataUsageByte
    );
    this.dealUsageBytesSelector.scrollLeft = itemWidthPx * dealUsageByteIndex;

    if (dealUsageByteIndex > 1) {
      const dataOverrideInput = document.querySelector(
        'input[name="deal_preference[override_data_usage]"]'
      );

      dataOverrideInput.value = true;
    }

    // Upfront fee for Handset deals
    const upfrontFee = formBody.get('deal_preference[upfront_fee]');
    const upfrontFeeItems = this.dealUpfrontFeeSelector.querySelectorAll('li');

    const upfrontFeeIndex = Array.from(upfrontFeeItems).findIndex(
      (n) => parseFloat(n.dataset.upfrontFee, 10) === parseFloat(upfrontFee, 10)
    );
    this.dealUpfrontFeeSelector.scrollLeft = itemWidthPx * upfrontFeeIndex;
  }

  hidePrefs() {
    if (!this.prefsOptionsContainerTarget.classList.contains('is-hidden')) {
      this.prefsOptionsContainerTarget.classList.add('is-hidden');
      this.prefsToggleButtonTarget.textContent = 'Personalise My Deals';
    }
  }

  showPrefs() {
    this.prefsOptionsContainerTarget.classList.remove('is-hidden');
    this.prefsToggleButtonTarget.textContent = 'Cancel Customisation';
  }

  hideDeals() {
    this.dealsListContainerTarget.classList.add('is-hidden');
  }

  showDeals() {
    this.dealsListContainerTarget.classList.remove('is-hidden');
  }

  hideContentAndRetrieveDeals(ev) {
    ev.preventDefault();
    if (this.appView == 'yes') {
      this.dealsListTarget.classList.add('is-hidden');
      this.showDeals();
    } else {
      this.snapToDeals();
    }

    let body = new URLSearchParams(new FormData(this.prefsformTarget));
    this.retrieve(body);

    this.hidePrefs();
  }

  snapToDeals() {
    this.dealsListContainerTarget.scrollIntoView();
  }

  showLoading() {
    this.loadingIconTarget.classList.remove('hide-deal-loader');
    this.loadingIconTarget.classList.add('is-spinning');
    // This seems strange compared to scrollIntoView() but this page is now
    // nested inside an iframe at bestmobiledeal.com and scrollIntoView()
    // affects the scroll of the parent page.
    document.documentElement.scrollTop = this.toggleContractTypeTarget.offsetTop;
  }

  hideLoading() {
    this.loadingIconTarget.classList.add('hide-deal-loader');
    this.loadingIconTarget.classList.remove('is-spinning');
  }

  retrieve(body = '') {
    this.showLoading();
    let url = this.buildFetchDataURL();
    console.log('rails', Rails);

    const aidMatch = document.cookie.match(/afUserId=(?<aid>.+?);/);
    const appsflyerID = aidMatch
      ? aidMatch.groups.aid
      : "none";

    let fetchData = {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-BMD-Appsflyer-ID': appsflyerID
      },
      body: body
    };
    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .catch((err) => {
        return `<h4 class="has-medium-margin-top has-h4-text has-center-aligned-text">There was an error retrieving the deals.</h4>`;
      })
      .then((data) => {
        this.hideLoading();
        this.updateList(data);
      });
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.updateList(xhr.response);
  }

  updateList(data) {
    let dealsList = this.dealsListTarget;
    this.dealsListTarget.classList.remove('is-hidden');
    dealsList.innerHTML = data;
  }

  resetSimOnlyDealsInputs() {
    let body = new URLSearchParams(new FormData(this.prefsformTarget));
    body.set('deal_preference[deal_network_ids][]', '');
    body.set('deal_preference[contract_lengths_sim_only][]', '');
    body.set('deal_preference[data_usage_bytes]', '');
    body.set('deal_preference[current_network]', '');
    this.dealNetworksSelector.scrollLeft = 0;
    this.dealCurrentNetworkSelector.scrollLeft = 0;
    this.dealContractLengthSimOnlySelector.scrollLeft = 0;
    this.dealUsageBytesSelector.scrollLeft = 0;
  }

  resetHandsetDealsInputs() {
    let body = new URLSearchParams(new FormData(this.prefsformTarget));
    body.set('deal_preference[deal_handset_ids][]', '');
    body.set('deal_preference[deal_product_version_ids][]', '');
    body.set('deal_preference[capacity]', '');
    body.set('deal_preference[deal_network_ids][]', '');
    body.set('deal_preference[upfront_fee]', '');
    body.set('deal_preference[contract_lengths_handset][]', '');
    body.set('deal_preference[contract_type]', 'sim_only');
    body.set('deal_preference[current_network]', '');
    this.dealNetworksSelector.scrollLeft = 0;
    this.dealCurrentNetworkSelector.scrollLeft = 0;
    this.dealHandsetsSelector.scrollLeft = 0;
    this.dealModelSelector.scrollLeft = 0;
    this.dealContractLengthHandsetSelector.scrollLeft = 0;
    this.dealCapacityDefaultSelector.scrollLeft = 0;
    this.dealCapacitySelector.scrollLeft = 0;
    this.dealUpfrontFeeSelector.scrollLeft = 0;
  }

  initContractTypeSelector() {
    const handsetFilters = document.querySelectorAll('.display_handset_only');
    const simOnlyFilters = document.querySelectorAll('.display_sim_only');

    const contractTypeRadios = document.querySelectorAll(
      'input[type="radio"][name="deal_preference[contract_type]"]'
    );
    contractTypeRadios.forEach((radio) => {
      radio.addEventListener('change', (e) => {
        const { value } = e.currentTarget;

        if (value === 'sim_only') {
          this.resetHandsetDealsInputs();
          let body = new URLSearchParams(new FormData(this.prefsformTarget));
          this.retrieve(body);

          handsetFilters.forEach((filter) => {
            filter.classList.add('is-hidden');
          });
          simOnlyFilters.forEach((filter) => {
            filter.classList.remove('is-hidden');
          });
        }

        if (value === 'handset') {
          this.resetSimOnlyDealsInputs();
          let body = new URLSearchParams(new FormData(this.prefsformTarget));
          this.retrieve(body);

          handsetFilters.forEach((filter) => {
            filter.classList.remove('is-hidden');
          });
          simOnlyFilters.forEach((filter) => {
            filter.classList.add('is-hidden');
          });
        }
      });
    });
  }
}

function itemWidthForScroll (itemElement) {
  const itemMarginRight = parseInt(
                            window.getComputedStyle(itemElement)["margin-right"]
                          );
  return itemElement.offsetWidth + itemMarginRight;
}

function onSliderScroll(element, setDataLabel, callback) {
  var list = element;
  var label = element.parentNode.getElementsByTagName('span')[0];

  // Calculate the width an item takes up. The left margin seems to be used for
  // moving all the list items to the middle of the page, so ignore it.
  const first = list.children[0];
  const index = Math.floor(list.scrollLeft / itemWidthForScroll(first));

  const items = list.querySelectorAll('li:not(.is-hidden)');
  var item = items[index];

  //For the constant labels
  if (setDataLabel) {
    if (label.hasAttribute('data-default')) {
      // We have the label value here and can do what we want with it
      if (item.hasAttribute('data-label')) {
        label.textContent = item.dataset.label;
      } else {
        label.textContent = label.dataset.default;
      }
    }
  }

  callback(item, this);
}

//Remove the .last-visible from previously last
function removeLastVisble(selector) {
  const lastVisible = document.querySelector(selector);
  if (lastVisible) {
    lastVisible.classList.remove('last-visible');
  }
}
