import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleFieldsButton", "fields", "errorContainer", "submitButton"]

  initialize() {
    if (!this.hasFieldsTarget) { return }
    if(!this.isFieldsHidden() || this.hasError()) {
      this.showFields();
    }
    if(!this.hasError()) {
      this.errorContainerTarget.classList.add("is-hidden");
    }
  }

  toggleFields() {
    if(this.isFieldsHidden()) {
      this.showFields();
    } else {
      this.hideFields();
    }
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.element.innerHTML = xhr.response;
  }

  onSubmit() {
    this.submitButtonTarget.classList.add('is-loading');
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  private

    isFieldsHidden() {
      return this.fieldsTarget.classList.contains("is-hidden");
    }

    hasError() {
      return this.errorContainerTarget.querySelectorAll(".is-error-message").length > 0;
    }

    showFields() {
      this.fieldsTarget.classList.remove("is-hidden");
      this.toggleFieldsButtonTarget.classList.add("has-vertically-inverted-icon");
    }

    hideFields() {
      this.fieldsTarget.classList.add("is-hidden");
      this.toggleFieldsButtonTarget.classList.remove("has-vertically-inverted-icon");
    }
}
