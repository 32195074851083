import { Controller } from "stimulus"
import { dispatchEvent } from "../../helpers"

export default class extends Controller {
  static targets = ["link", "content"]

  initialize() {
    this.showActiveTabsFromLocationHash();
  }

  onClickTabLink(e) {
    e.preventDefault();
    let tabLink = e.currentTarget;
    if(tabLink.classList.contains("is-active")) return;
    window.location.hash = tabLink.getAttribute('href');
    this.changeTab(tabLink); 
    dispatchEvent(window, 'resize');
  }

  private

    changeTab(tabLink) {
      let tabGroup = tabLink.getAttribute("data-tab-group");
      this.clearActiveTabsForTabGroup(tabGroup);
      this.activateTabContent(tabLink);   
    }

    clearActiveTabsForTabGroup(tabGroup) {
      [...this.linkTargets, ...this.contentTargets].forEach((target) => {
        if(target.getAttribute("data-tab-group") == tabGroup) {
          target.classList.remove("is-active");
        }
      });
    }

    activateTabContent(tabLink) {
      tabLink.classList.add("is-active");
      let activeContent = this.contentTargets.find((contentTarget) => {
        return contentTarget.getAttribute("data-tab-content") == tabLink.getAttribute('href');
      });
      activeContent.classList.add("is-active");
    }

    showActiveTabsFromLocationHash() {
      let tabLink = this.findTabLinkFromLocationHash();
      if(!tabLink) return;
      this.changeTab(tabLink);
      this.activeParentTabsForTabLink(tabLink);
    }

    findTabLinkFromLocationHash() {
      let locationHash = window.location.hash
      return this.linkTargets.find((linkTarget) => {
        return locationHash == linkTarget.getAttribute('href');
      });
    }

    activeParentTabsForTabLink(tabLink) {
      let parentTabLink = this.findParentTab(tabLink);
      if(!parentTabLink) return;
      do {
        this.changeTab(parentTabLink); 
        parentTabLink = this.findParentTab(parentTabLink);
      } while (parentTabLink);
    }

    findParentTab(tabLink) {
      let tabGroup = tabLink.getAttribute("data-tab-group");
      return this.linkTargets.find((linkTarget) => {
        return tabGroup == linkTarget.getAttribute('href');
      });
    }
}
