import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["details", "form", "sideTabs", "submitButton"]

  initialize() {
    this.checkShouldShowForm();
  }

  onClickEdit(e) {
    e.preventDefault();
    this.showForm();
  }

  onClickCancel(e) {
    e.preventDefault();
    this.hideForm();
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.element.innerHTML = xhr.response;
    this.checkShouldShowForm();
  }

  onSubmit(e) {
    this.submitButtonTarget.classList.add('is-loading');
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  private

    checkShouldShowForm() {
      if(this.formTarget.getAttribute("data-has-error") == 'true') {
        this.showForm();
      }
    }

    showForm() {
      this.detailsTarget.classList.add("is-hidden");
      this.sideTabsTarget.classList.add("is-hidden");
      this.formTarget.classList.remove("is-hidden");
    }

    hideForm() {
      this.detailsTarget.classList.remove("is-hidden");
      this.sideTabsTarget.classList.remove("is-hidden");
      this.formTarget.classList.add("is-hidden");
    }
}
