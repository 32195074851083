export const chevronDownSvg = `
<svg viewBox="0 0 23 13"  aria-hidden="true" class="has-stroke">
  <g stroke-linecap="round" stroke-linejoin="round">
    <g transform="translate(-705.000000, -525.000000)" stroke-width="4">
      <polyline transform="translate(716.186953, 531.103330) rotate(-270.000000) translate(-716.186953, -531.103330) " points="712.102922 540 720.270984 531.098021 712.102922 522.206659"></polyline>
    </g>
  </g>
</svg>
`;

export const triangleDownSmallSvg = `
<svg viewBox="0 0 11 8" aria-hidden="true" class="has-fill">
  <g transform="translate(-996.000000, -489.000000)">
    <polygon transform="translate(1001.902727, 493.142815) rotate(-270.000000) translate(-1001.902727, -493.142815)" points="1005.40273 493.142815 998.402727 498.142815 998.402727 488.142815"></polygon>
  </g>
</svg>
`;

export const tickSvg = `
<svg viewBox="0 0 29 28" aria-hidden="true" class="has-fill">
  <g transform="translate(-34.000000, -17.000000)">
    <path d="M46.35754,44.0976562 C45.6992493,44.0976562 45.0605196,43.8377454 44.574626,43.3638223 L35.1579919,34.1784046 C34.1066569,33.1528838 34.0526687,31.4342675 35.0374966,30.3394915 C36.0223244,29.2449871 37.6727457,29.1884966 38.7238199,30.2140173 L45.8919897,37.2060808 L57.7206175,18.6812543 C58.5179212,17.4319441 60.137045,17.0930008 61.3365215,17.9232489 C62.535998,18.7537686 62.8617528,20.4397943 62.0641883,21.6888329 L48.5295862,42.8855537 C48.1047227,43.5506756 47.4206116,43.987391 46.6585176,44.0794598 C46.5581048,44.0916813 46.457692,44.0976562 46.35754,44.0976562"></path>
  </g>
</svg>
`

export const calendarSvg = `
<svg viewBox="0 0 24 24" aria-hidden="true" class="has-fill">
  <g transform="translate(-1070.000000, -424.000000)">
    <g transform="translate(1070.985352, 424.414062)">
      <path d="M3.01677817,3.82009861 C2.3107545,3.82009861 1.73635058,4.39450253 1.73635058,5.1005262 L1.73635058,19.9037556 C1.73635058,20.6097793 2.3107545,21.1841832 3.01677817,21.1841832 L19.906939,21.1841832 C20.6129627,21.1841832 21.1873666,20.6097793 21.1873666,19.9037556 L21.1873666,5.1005262 C21.1873666,4.39450253 20.6129627,3.82009861 19.906939,3.82009861 L3.01677817,3.82009861 Z M19.906939,22.9205916 L3.01677817,22.9205916 C1.35329887,22.9205916 -5.78802819e-05,21.5673507 -5.78802819e-05,19.9037556 L-5.78802819e-05,5.1005262 C-5.78802819e-05,3.4370469 1.35329887,2.08369015 3.01677817,2.08369015 L19.906939,2.08369015 C21.5704183,2.08369015 22.9237751,3.4370469 22.9237751,5.1005262 L22.9237751,19.9037556 C22.9237751,21.5673507 21.5704183,22.9205916 19.906939,22.9205916 Z"></path>
      <path d="M5.67542789,5.88236148 C5.19583188,5.88236148 4.80722366,5.4936375 4.80722366,5.01415725 L4.80722366,0.868192653 C4.80722366,0.388596637 5.19583188,-1.15760564e-05 5.67542789,-1.15760564e-05 C6.15502391,-1.15760564e-05 6.54363212,0.388596637 6.54363212,0.868192653 L6.54363212,5.01415725 C6.54363212,5.4936375 6.15502391,5.88236148 5.67542789,5.88236148"></path>
      <path d="M11.3932589,5.88236148 C10.9136629,5.88236148 10.5250547,5.4936375 10.5250547,5.01415725 L10.5250547,0.868192653 C10.5250547,0.388596637 10.9136629,-1.15760564e-05 11.3932589,-1.15760564e-05 C11.8728549,-1.15760564e-05 12.2614631,0.388596637 12.2614631,0.868192653 L12.2614631,5.01415725 C12.2614631,5.4936375 11.8728549,5.88236148 11.3932589,5.88236148"></path>
      <path d="M17.0778434,5.88236148 C16.5982474,5.88236148 16.2096392,5.4936375 16.2096392,5.01415725 L16.2096392,0.868192653 C16.2096392,0.388596637 16.5982474,-1.15760564e-05 17.0778434,-1.15760564e-05 C17.5574395,-1.15760564e-05 17.9460477,0.388596637 17.9460477,0.868192653 L17.9460477,5.01415725 C17.9460477,5.4936375 17.5574395,5.88236148 17.0778434,5.88236148"></path>
      <path d="M7.93275889,9.63706694 C7.93275889,10.3882372 7.32374256,10.9972536 6.57257226,10.9972536 C5.82140196,10.9972536 5.21238564,10.3882372 5.21238564,9.63706694 C5.21238564,8.88578088 5.82140196,8.27688032 6.57257226,8.27688032 C7.32374256,8.27688032 7.93275889,8.88578088 7.93275889,9.63706694"></path>
      <path d="M12.8318964,9.63706694 C12.8318964,10.3882372 12.2228801,10.9972536 11.4717098,10.9972536 C10.7205395,10.9972536 10.1115232,10.3882372 10.1115232,9.63706694 C10.1115232,8.88578088 10.7205395,8.27688032 11.4717098,8.27688032 C12.2228801,8.27688032 12.8318964,8.88578088 12.8318964,9.63706694"></path>
      <path d="M17.7311498,9.63706694 C17.7311498,10.3882372 17.1221334,10.9972536 16.3709631,10.9972536 C15.6197928,10.9972536 15.0107765,10.3882372 15.0107765,9.63706694 C15.0107765,8.88578088 15.6197928,8.27688032 16.3709631,8.27688032 C17.1221334,8.27688032 17.7311498,8.88578088 17.7311498,9.63706694"></path>
      <path d="M7.93275889,13.341405 C7.93275889,14.0925753 7.32374256,14.7015916 6.57257226,14.7015916 C5.82140196,14.7015916 5.21238564,14.0925753 5.21238564,13.341405 C5.21238564,12.5901189 5.82140196,11.9812184 6.57257226,11.9812184 C7.32374256,11.9812184 7.93275889,12.5901189 7.93275889,13.341405"></path>
      <path d="M12.8318964,13.341405 C12.8318964,14.0925753 12.2228801,14.7015916 11.4717098,14.7015916 C10.7205395,14.7015916 10.1115232,14.0925753 10.1115232,13.341405 C10.1115232,12.5901189 10.7205395,11.9812184 11.4717098,11.9812184 C12.2228801,11.9812184 12.8318964,12.5901189 12.8318964,13.341405"></path>
      <path d="M17.7311498,13.341405 C17.7311498,14.0925753 17.1221334,14.7015916 16.3709631,14.7015916 C15.6197928,14.7015916 15.0107765,14.0925753 15.0107765,13.341405 C15.0107765,12.5901189 15.6197928,11.9812184 16.3709631,11.9812184 C17.1221334,11.9812184 17.7311498,12.5901189 17.7311498,13.341405"></path>
      <path d="M7.93275889,17.045743 C7.93275889,17.7969133 7.32374256,18.4059297 6.57257226,18.4059297 C5.82140196,18.4059297 5.21238564,17.7969133 5.21238564,17.045743 C5.21238564,16.294457 5.82140196,15.6855564 6.57257226,15.6855564 C7.32374256,15.6855564 7.93275889,16.294457 7.93275889,17.045743"></path>
      <path d="M12.8318964,17.045743 C12.8318964,17.7969133 12.2228801,18.4059297 11.4717098,18.4059297 C10.7205395,18.4059297 10.1115232,17.7969133 10.1115232,17.045743 C10.1115232,16.294457 10.7205395,15.6855564 11.4717098,15.6855564 C12.2228801,15.6855564 12.8318964,16.294457 12.8318964,17.045743"></path>
      <path d="M17.7311498,17.045743 C17.7311498,17.7969133 17.1221334,18.4059297 16.3709631,18.4059297 C15.6197928,18.4059297 15.0107765,17.7969133 15.0107765,17.045743 C15.0107765,16.294457 15.6197928,15.6855564 16.3709631,15.6855564 C17.1221334,15.6855564 17.7311498,16.294457 17.7311498,17.045743"></path>
    </g>
  </g>
</svg>
`
