import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['list', 'statusFilter'];

  initialize() {
    this.timeRange = '';
    this.path = this.element.getAttribute('data-path');
  }

  onChange() {
    let data = this.setData();
    this.filter(data);
  }

  onRadioChange(e) {
    this.timeRange = e.target.value;
    this.onChange();
  }

  filter(data) {
    let url = this.path;
    let fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };

    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.updateList(data);
      });
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.updateList(xhr.response);
  }

  updateList(data) {
    let filteredList = this.listTarget;
    filteredList.innerHTML = data;
  }

  setData() {
    return {
      status: this.statusFilterTarget.value,
      time_range: this.timeRange
    };
  }
}
