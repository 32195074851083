import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["individualAnswer"]

  onAnswer() {
    if (!this.answeredIndividual) {
      return;
    }
    this.allowanceTotalsController.onAnswer();
  }

private

  get answeredIndividual() {
    let checked = false;
    this.individualAnswerTargets.forEach((answer) => {
      if (answer.checked) {
        checked = true;
      }
    });
    return checked;
  }

  get allowanceTotalsController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "devices--data-allowance-totals");
  }
}
