import { Controller } from "stimulus"

export default class extends Controller {
  openMobileNav() {
    if(this.switchDeviceMenuController) {
      this.switchDeviceMenuController.close();
    }
    this.mobileNavController.open();
  }

  openSwitchDeviceMenu() {
    this.mobileNavController.close();
    this.switchDeviceMenuController.open();
  }

  loadSwitchDeviceMenu() {
    this.switchDeviceController.retrieve();
  }

  get mobileNavController() {
    let element = document.querySelector('[data-id="ui--mobile-nav"]');
    return this.application.getControllerForElementAndIdentifier(element, "ui--mobile-nav");
  }

  get switchDeviceController() {
    let element = document.querySelector('[data-id="devices--switch-device"]');
    return this.application.getControllerForElementAndIdentifier(element, "devices--switch-device");
  }

  get switchDeviceMenuController() {
    let element = document.querySelector('[data-id="ui--switch-device-menu"]');
    return this.application.getControllerForElementAndIdentifier(element, "ui--switch-device-menu");
  }
}
