import { Controller } from "stimulus"

export default class extends Controller {

  toggleOpen() {
    if (!this.element.classList.contains('is-open')) {
      this.element.classList.add('is-open');
    } else {
      this.element.classList.remove('is-open');
    }
  }
}
