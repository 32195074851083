import { Controller } from "stimulus"
import c3 from "c3"

export default class extends Controller {

  static targets = ["chartContainer", "chart"];

  initialize() {
    this.data_used = this.element.getAttribute("data-monthly-data-used-percentage");
    this.generateChart()
  }

  private

  generateChart() {
    c3.generate({
      data: {
        columns: [
            ['data', this.data_used]
        ],
        type: 'gauge',
      },
      gauge: {
        label: {
            format: function(value, ratio) {
                return value+"%";
            },
            show: false // to turn off the min/max labels.
        },
        width: 50 // for adjusting arc thickness
      },
      color: {
        pattern: ['#73CFDE', '#F50057'],
        threshold: {
          values: [100]
        }
      },
      legend: {
        show: false
      },
      bindto: this.chartTarget,
      onrendered: function () {
        if(this.data.targets[0].values[0].value >= 100) {
          d3.select('.c3-chart-arc .c3-gauge-value').style("fill", "#F50057" )
        }
      }
    });
  }
}
