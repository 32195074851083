import { Controller } from "stimulus"
import { dispatchEvent, listenForClickOffElement } from "../../helpers"
import { triangleDownSmallSvg, tickSvg } from "../../icons"

export default class extends Controller {
  static targets = ["realSelect", "fakeSelect", "fakeOptions", "fakeOption", "currentValue"]

  initialize() {
    this.realOptions = Array.from(this.realSelectTarget.options);
    this.selectableSubset = this.element.getAttribute('data-selectable-subset');
    this.subsetOptions = this.realOptions.filter(r => this.selectableSubset.includes(r.value))
    this.placeholder = this.element.getAttribute('data-placeholder') || 'Please select';
    this.selectedText = this.element.getAttribute('data-selected-text') || 'selected of';
    this.selectAllText = this.element.getAttribute('data-select-all-text') || 'Select all';
    this.selectSubsetText = this.element.getAttribute('data-select-subset-text');
    this.allSelectedText = this.element.getAttribute('data-all-selected-text') || 'All selected';
    this.showCount = this.element.getAttribute('data-show-count');
    this.allowSelectAll = this.element.getAttribute('data-allow-select-all');
    this.allowSelectSubset = this.element.getAttribute('data-allow-select-subset');
    this.renderTemplate();
    this.highlightFirstFakeOption();
    this.showValueFromRealSelect();
    this.showSelectedFakeOptions();
    listenForClickOffElement(this.element, this.close.bind(this));
    this.listenForRealSelectChange();
  }

  toggleOpen(e) {
    e.preventDefault();
    if(this.element.classList.contains('is-open')) {
      this.close();
    } else {
      this.open();
    }
  }

  onClickFakeOption(e) {
    e.preventDefault();
    e.stopPropagation();
    let fakeOption = e.currentTarget;
    let index = fakeOption.getAttribute('data-index');
    if(this.hasSelectAllOption()) {
      if(index == 0) {
        this.toggleSelectAllRealOptions();
      } else if(index == 1 && this.hasSelectSubsetOption()) {
          this.toggleSelectSubsetRealOptions();
      } else {
        this.toggleRealSelectOptionByIndex(index);
      }
    } else if(this.hasSelectSubsetOption()) {
      if(index == 0) {
        this.toggleSelectSubsetRealOptions();
      } else {
        this.toggleRealSelectOptionByIndex(index);
      }
    } else {
      this.toggleRealSelectOptionByIndex(index);
    }

    this.showValueFromRealSelect();
    this.showSelectedFakeOptions();
    this.fakeSelectTarget.focus();
  }

  onMouseoverFakeOption(e) {
    let highlightedFakeOptionIndex = e.currentTarget.getAttribute('data-index');
    this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
  }

  onKeyDown(e) {
    let highlightedFakeOptionIndex = 0;
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if(!this.isOpen()) { this.open(); }
        this.handleKeyPressFocus();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        if(highlightedFakeOptionIndex < (this.fakeOptionTargets.length - 1)) {
          highlightedFakeOptionIndex++;
          this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if(!this.isOpen()) { this.open(); }
        this.handleKeyPressFocus();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        if(highlightedFakeOptionIndex > 0) {
          highlightedFakeOptionIndex--;
          this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
        }
        break;
      case "Enter":
        e.preventDefault();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        let highlightedFakeOption = this.findFakeOptionByIndex(highlightedFakeOptionIndex);
        dispatchEvent(highlightedFakeOption, 'click');
        break;
      case "Tab":
        if(this.isOpen()) { this.close(); }
        break;
      case "Escape":
        e.preventDefault();
        this.close();
        break;
    }
  }

  private

    showValueFromRealSelect() {
      let value = this.placeholder;
      let selectedValues = this.realOptions.reduce((filtered, realOption) => {
        if(realOption.selected) filtered.push(realOption.innerText);
        return filtered;
      }, []);

      if(this.showCount == 'true') {
        if (selectedValues.length == this.realOptions.length) {
          value = this.allSelectedText;
        } else {
          value = `${selectedValues.length} ${this.selectedText} ${this.realOptions.length}`;
        }
      } else if(selectedValues.length) {
        value = selectedValues.join(", ");
      }

      this.currentValueTarget.textContent = value;
    }

    close() {
      this.element.classList.remove('is-open');
    }

    open() {
      this.element.classList.add('is-open');
    }

    isOpen() {
      return this.element.classList.contains('is-open');
    }

    listenForRealSelectChange() {
      this.realSelectTarget.addEventListener("change", () => {
        this.showValueFromRealSelect();
      });
    }

    toggleHasValueSelectedClass() {
      if(this.realSelectTarget.value) {
        this.element.classList.add('has-value-selected');
      } else {
        this.element.classList.remove('has-value-selected');
      }
    }

    toggleRealSelectOptionByIndex(index) {
      let realOption = this.findRealOptionByIndex(index);
      realOption.selected = !realOption.selected;
    }

    toggleSelectAllRealOptions() {
      if(!this.hasSelectAllOption()) return
      let selectedRealOptions = this.realOptions.reduce((filtered, realOption) => {
        if(realOption.selected) filtered.push(realOption);
        return filtered;
      }, []);
      let shouldSelectAll = true;
      if(selectedRealOptions.length == this.realOptions.length) {
        shouldSelectAll = false;
      }
      this.realOptions.forEach((realOption) => {
        realOption.selected = shouldSelectAll;
      });
    }

    toggleSelectSubsetRealOptions() {
      if(!this.hasSelectSubsetOption()) return
      let selectedRealOptions = this.subsetOptions.reduce((filtered, realOption) => {
        if(realOption.selected) filtered.push(realOption);
        return filtered;
      }, []);
      let shouldSelectAll = true;
      if(this.subsetOptions.every((value, index) => value === selectedRealOptions[index])) {
        shouldSelectAll = false;
      }
      this.subsetOptions.forEach((realOption) => {
        realOption.selected = shouldSelectAll;
      });
    }

    showSelectedFakeOptions() {
      let selectedCount = 0;
      let selectedOptions = [];
      this.fakeOptionTargets.forEach((fakeOption) => {
        fakeOption.classList.remove('is-selected');
        let index = fakeOption.getAttribute('data-index');
        let realOption = this.findRealOptionByIndex(index);
        if(realOption && realOption.selected) {
          fakeOption.classList.add('is-selected');
          selectedCount++;
          selectedOptions.push(realOption)
        }
      });

      if(this.hasSelectAllOption()) {
        if(this.realOptions.every((value, index) => value === selectedOptions[index])) {
          this.fakeOptionTargets[0].classList.add('is-selected');
        } else {
          this.fakeOptionTargets[0].classList.remove('is-selected');
        }
      }

      if(this.hasSelectSubsetOption()) {
        let fakeIndex = this.hasSelectAllOption() ? 1 : 0
        if(this.subsetOptions.every((value, index) => value === selectedOptions[index])) {
          this.fakeOptionTargets[fakeIndex].classList.add('is-selected');
        } else {
          this.fakeOptionTargets[fakeIndex].classList.remove('is-selected');
        }
      }

      this.toggleHasValueSelectedClass();
    }

    highlightFakeOptionByIndex(index) {
      this.removeHighlight();
      let fakeOptionToHighlight = this.findFakeOptionByIndex(index)
      if(fakeOptionToHighlight) {
        fakeOptionToHighlight.classList.add('is-highlighted');
      }
    }

    getHighlightedFakeOptionIndex() {
      let selectedFakeOption = this.findHighlightedFakeOption();
      return selectedFakeOption.getAttribute('data-index');
    }

    removeHighlight() {
      this.fakeOptionTargets.forEach((fakeOption) => {
        fakeOption.classList.remove('is-highlighted');
      });
    }

    findHighlightedFakeOption() {
      return this.fakeOptionTargets.find(fakeOption => {
        return fakeOption.classList.contains('is-highlighted');
      });
    }

    findFakeOptionByIndex(index) {
      return this.fakeOptionTargets.find(fakeOption => {
        return fakeOption.getAttribute('data-index') == index;
      });
    }

    findRealOptionByIndex(index) {
      if(this.hasSelectAllOption()) {
        index--;
      }
      if(this.hasSelectSubsetOption()) {
        index--;
      }
      return this.realOptions[index];
    }

    highlightFirstFakeOption() {
      this.fakeOptionTargets[0].classList.add('is-highlighted');
    }

    hasSelectAllOption() {
      return this.allowSelectAll == 'true';
    }

    hasSelectSubsetOption() {
      return this.allowSelectSubset == 'true';
    }

    handleKeyPressFocus() {
      this.fakeOptionsTarget.focus();
      setTimeout(() => {
        this.fakeSelectTarget.focus();
      }, 500);
    }

    renderTemplate() {
      const template = `
        <div class="form-field-select-fake" tabindex="0" data-target="forms--multiselect.fakeSelect" data-action="keydown->forms--multiselect#onKeyDown">
          <span class="form-field-select-fake-current-value" data-target="forms--multiselect.currentValue">
            ${this.placeholder}
          </span>
          <i class="form-field-select-fake-icon">
            ${triangleDownSmallSvg}
          </i>
        </div>
        <ul class="form-field-select-fake-options" data-target="forms--multiselect.fakeOptions" tabindex="0">
          ${this.renderSelectAllOption()}
          ${this.renderSelectSubsetOption()}
          ${this.renderOptions()}
        </ul>
      `;
      this.element.insertAdjacentHTML('beforeend', template);
    }

    renderOptions() {
      return this.realOptions.map((realOption, index) => {
        var offsetIndex = (this.hasSelectAllOption()) ? (index + 1) : index;
        offsetIndex = (this.hasSelectSubsetOption()) ? (offsetIndex + 1) : offsetIndex;
        return this.renderOption(offsetIndex, realOption.value, realOption.innerText)
      }).join('');
    }

    renderSelectAllOption() {
      if(!this.hasSelectAllOption()) return '';
      return this.renderOption(0, '', this.selectAllText);
    }

    renderSelectSubsetOption() {
      if(!this.hasSelectSubsetOption()) return '';
      if(this.hasSelectAllOption()) {
        return this.renderOption(1, '', this.selectSubsetText);
      }
      return this.renderOption(0, '', this.selectSubsetText);
    }

    renderOption(index, value, text) {
      return `
        <li class="form-field-select-fake-option" data-value="${value}" data-index="${index}"data-action="click->forms--multiselect#onClickFakeOption click->forms--validator#validate mouseover->forms--multiselect#onMouseoverFakeOption" data-target="forms--multiselect.fakeOption">
          <i class="form-field-select-fake-option-selected-icon">
            ${tickSvg}
          </i>
          <span>
            ${text}
          </span>
        </li>
      `;
    }
}
