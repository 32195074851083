import { Controller } from "stimulus"
import { dispatchEvent, listenForClickOffElement } from "../../helpers"
import { chevronDownSvg, triangleDownSmallSvg, calendarSvg } from "../../icons"

export default class extends Controller {
  static targets = ["realSelect", "fakeSelect", "fakeOptions", "fakeOption", "currentValue"]
  
  initialize() {
    this.realOptions = Array.from(this.realSelectTarget.options);
    this.renderTemplate();
    this.showValueFromRealSelect();
    listenForClickOffElement(this.element, this.close.bind(this))
    this.listenForRealSelectChange();
  }

  toggleOpen(e) {
    e.preventDefault();
    if(this.element.classList.contains('is-open')) {
      this.close();
    } else {
      this.open();
    }
  }

  onClickFakeOption(e) {
    e.preventDefault();
    this.realSelectTarget.value = e.currentTarget.getAttribute('data-value');
    let newValue = e.currentTarget.innerText;
    this.showFakeSelectValue(newValue);
    dispatchEvent(this.realSelectTarget, 'change');
    this.fakeSelectTarget.focus();
  }

  onMouseoverFakeOption(e) {
    let highlightedFakeOptionIndex = e.currentTarget.getAttribute('data-index');
    this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
  }

  onKeyDown(e) {
    let highlightedFakeOptionIndex = 0;
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if(!this.isOpen()) { this.open(); }
        this.handleKeyPressFocus();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        if(highlightedFakeOptionIndex < (this.fakeOptionTargets.length - 1)) {
          highlightedFakeOptionIndex++;
          this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if(!this.isOpen()) { this.open(); }
        this.handleKeyPressFocus();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        if(highlightedFakeOptionIndex > 0) {
          highlightedFakeOptionIndex--;
          this.highlightFakeOptionByIndex(highlightedFakeOptionIndex)
        }
        break;
      case "Enter":
        e.preventDefault();
        highlightedFakeOptionIndex = this.getHighlightedFakeOptionIndex();
        let highlightedFakeOption = this.findFakeOptionByIndex(highlightedFakeOptionIndex);
        dispatchEvent(highlightedFakeOption, 'click');
        this.close();
        break;
      case "Tab":
        if(this.isOpen()) { this.close(); }
        break;
      case "Escape":
        e.preventDefault();
        this.close();
        break;
    }
  }

  private

    showValueFromRealSelect() {
      let selectedIndex = this.realSelectTarget.selectedIndex;
      let realSelectedOption = this.realSelectTarget.options[selectedIndex]
      if(!realSelectedOption) return;
      let fakeSelectValue = realSelectedOption.innerText;
      this.showFakeSelectValue(fakeSelectValue);
    }

    showFakeSelectValue(value) {
      this.currentValueTarget.textContent = value;
      this.toggleHasValueSelectedClass();
    }

    close() {
      this.element.classList.remove('is-open');
    }

    open() {
      this.element.classList.add('is-open');
      this.highlightSelectedFakeOption();
    }

    isOpen() {
      return this.element.classList.contains('is-open');
    }

    listenForRealSelectChange() {
      this.realSelectTarget.addEventListener("change", () => {
        this.showValueFromRealSelect();
      });
    }

    toggleHasValueSelectedClass() {
      if(this.realSelectTarget.value) {
        this.element.classList.add('has-value-selected');
      } else {
        this.element.classList.remove('has-value-selected');
      }
    }

    highlightSelectedFakeOption() {
      this.removeHighlight();
      let selectedFakeOption = this.findSelectedFakeOption();
      selectedFakeOption.classList.add('is-highlighted');
    }

    highlightFakeOptionByIndex(index) {
      this.removeHighlight();
      let fakeOptionToHighlight = this.findFakeOptionByIndex(index)
      if(fakeOptionToHighlight) {
        fakeOptionToHighlight.classList.add('is-highlighted');
      } 
    }

    getHighlightedFakeOptionIndex() {
      let selectedFakeOption = this.findHighlightedFakeOption();
      return selectedFakeOption.getAttribute('data-index');
    }

    removeHighlight() {
      this.fakeOptionTargets.forEach((fakeOption) => {
        fakeOption.classList.remove('is-highlighted');
      });
    }

    findHighlightedFakeOption() {
      return this.fakeOptionTargets.find(fakeOption => {
        return fakeOption.classList.contains('is-highlighted');
      });
    }

    findSelectedFakeOption() {
      let realValue = this.realSelectTarget.value;
      return this.fakeOptionTargets.find(fakeOption => {
        return fakeOption.getAttribute('data-value') == realValue;
      });
    }

    findFakeOptionByIndex(index) {
      return this.fakeOptionTargets.find(fakeOption => {
        return fakeOption.getAttribute('data-index') == index;
      });
    }

    handleKeyPressFocus() {
      this.fakeOptionsTarget.focus();
      setTimeout(() => {
        this.fakeSelectTarget.focus();
      }, 500);
    }

    renderTemplate() {
      const template = `
        <div class="form-field-select-fake" tabindex="0" data-target="forms--select.fakeSelect" data-action="keydown->forms--select#onKeyDown">
          ${this.renderCurrentValueTemplate()}
        </div>
        <ul class="form-field-select-fake-options" data-target="forms--select.fakeOptions" tabindex="0">
          ${this.renderOptions()}
        </ul>
      `;
      this.element.insertAdjacentHTML('beforeend', template);
    }

    renderCurrentValueTemplate() {
      let icon = triangleDownSmallSvg;
      if(this.element.classList.contains('is-chunky')) {
        icon = chevronDownSvg;
      }
      if(this.element.classList.contains('is-date')) {
        icon = calendarSvg;
      }

      let iconTemplate = `
        <i class="form-field-select-fake-icon">
          ${icon}
        </i>
      `;
      let currentValueTemplate = `
        <span class="form-field-select-fake-current-value" data-target="forms--select.currentValue">
          ${this.realOptions[0].innerText}
        </span>
      `;

      if(this.element.classList.contains('is-date')) {
        return `${iconTemplate}${currentValueTemplate}`;
      }
      return `${currentValueTemplate}${iconTemplate}`;
    }

    renderOptions() {
      return this.realOptions.map((realOption, index) =>
        `
          <li class="form-field-select-fake-option" data-value="${realOption.value}" data-index="${index}" data-action="click->forms--select#onClickFakeOption mouseover->forms--select#onMouseoverFakeOption" data-target="forms--select.fakeOption">
            ${realOption.innerText}
          </li>
        `
      ).join('');
    }
}
