import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['order', 'search', 'list'];

  clients(ev) {
    const data = {
      query: this.searchTarget.value,
      order: this.orderTarget.value
    };
    const url = `${location.href}/clients`;
    const fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };

    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.listTarget.innerHTML = data;
      });
  }
}
