import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  initialize() {
    this.checkOverflow();
    window.addEventListener('resize', ()  => {
      this.checkOverflow();
    });
  }

  onClickButton(e) {
    e.preventDefault();
    this.toggleOpen();
  }

  private

    checkOverflow() {
      setTimeout(() => {
        if(this.contentHasOverflow()) {
          this.buttonTarget.classList.remove('is-hidden');
        } else {
          this.buttonTarget.classList.add('is-hidden');
        }
      }, 0);
    }

    toggleOpen() {
      if(this.element.classList.contains('is-open')) {
        this.element.classList.remove('is-open');
      } else {
        this.element.classList.add('is-open');
      }
    }

    contentHasOverflow() {
      if(this.element.classList.contains('is-open')) return true;
      if(this.contentTarget.offsetWidth == 0) return true
      return (this.contentTarget.offsetWidth < this.contentTarget.scrollWidth);
    }
}
