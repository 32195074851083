import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['slide', 'slideButton'];

  initialize() {
    const initialSlideIndex = parseInt(
      this.element.getAttribute('data-current-slide-index')
    );
    this.showSlideByIndex(initialSlideIndex);

    document
      .getElementsByClassName('slider')[0]
      .addEventListener('scroll', function() {
        var fontSize = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        var offset = this.scrollLeft - fontSize;
        var index = Math.round(offset / (23.5 * fontSize)) + 1;

        Array.from(document.querySelectorAll('.page')).forEach((el) =>
          el.classList.remove('current')
        );
        document.getElementById('page-' + index).classList.add('current');
      });

    document.querySelector('#recommendations div').addEventListener('scroll', function (ev) {
      // The panels are different widths!
      const panelWidth = Math.min(
                           ev.target.children[0].offsetWidth,
                           ev.target.children[1].offsetWidth,
                           ev.target.children[2].offsetWidth);

      const index = Math.floor(ev.target.scrollLeft / panelWidth);

      const leftChevron = document.querySelector('button.deal-left');
      const rightChevron = document.querySelector('button.deal-right');

      if (leftChevron) {
        leftChevron.disabled = index == 0;
      }
      if (leftChevron) {
        rightChevron.disabled = index == 2;
      }
    });

    document.querySelector("button.deal-right").addEventListener("click", function (ev) {
      ev.preventDefault();

      const div = document.querySelector('#recommendations div');
      div.scrollLeft += div.children[0].offsetWidth;
    }.bind(this));

    document.querySelector("button.deal-left").addEventListener("click", function (ev) {
      ev.preventDefault();

      const div = document.querySelector('#recommendations div');
      div.scrollLeft -= div.children[0].offsetWidth;
    }.bind(this));

  }

  showNextSlide(e) {
    e.preventDefault();
    if (this.currentSlideIndex >= this.slideTargets.length - 1) return;
    let nextSlideIndex = this.currentSlideIndex + 1;
    this.showSlideByIndex(nextSlideIndex);
  }

  showPreviousSlide(e) {
    e.preventDefault();
    if (this.currentSlideIndex <= 0) return;
    let previousSlideIndex = this.currentSlideIndex - 1;
    this.showSlideByIndex(previousSlideIndex);
  }

  showNextSlideAndLoop(e) {
    e.preventDefault();
    let nextSlideIndex =
      this.currentSlideIndex >= this.slideTargets.length - 1
        ? 0
        : this.currentSlideIndex + 1;
    this.showSlideByIndex(nextSlideIndex);
  }

  showPreviousSlideAndLoop(e) {
    e.preventDefault();
    let nextSlideIndex =
      this.currentSlideIndex <= 0
        ? this.slideTargets.length - 1
        : this.currentSlideIndex - 1;
    this.showSlideByIndex(nextSlideIndex);
  }

  jumpToSlide(e) {
    e.preventDefault();
    let jumpToSlideIndex = parseInt(
      e.currentTarget.getAttribute('data-slide-index')
    );
    this.showSlideByIndex(jumpToSlideIndex);
  }

  private;

  showSlideByIndex(index) {
    let slideTarget = this.slideTargets.find((slideTarget) => {
      return parseInt(slideTarget.getAttribute('data-slide-index')) == index;
    });
    if (slideTarget) {
      this.setCurrentSlideIndex(index);
      this.hideAllSlides();
      this.showSlide(slideTarget);
      this.updateActiveSlideButtonAtIndex(index);
    }
  }

  setCurrentSlideIndex(index) {
    this.currentSlideIndex = index;
    this.element.setAttribute(
      'data-current-slide-index',
      this.currentSlideIndex
    );
  }

  showSlide(slideTarget) {
    slideTarget.classList.remove('is-hidden');
  }

  hideSlide(slideTarget) {
    slideTarget.classList.add('is-hidden');
  }

  hideAllSlides() {
    this.slideTargets.forEach((slide) => {
      this.hideSlide(slide);
    });
  }

  updateActiveSlideButtonAtIndex(index) {
    this.removeIsActiveFromSlideButtons();
    let slideButtonTarget = this.slideButtonTargets.find(
      (slideButtonTarget) => {
        return (
          parseInt(slideButtonTarget.getAttribute('data-slide-index')) == index
        );
      }
    );
    slideButtonTarget.classList.add('is-active');
  }

  removeIsActiveFromSlideButtons() {
    this.slideButtonTargets.forEach((slideButtonTarget) => {
      slideButtonTarget.classList.remove('is-active');
    });
  }
}
