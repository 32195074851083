import { Controller } from "stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ["item", "editDeleteButtonList", "editForm", "startDateText", "allowanceText", "allowanceFieldInput", "dataUnitButton", "startDateFieldInput", "startDateField", "allowanceField"]

  delete(e) {
    e.preventDefault();
    this.id = this.itemTarget.getAttribute('data-list-id');
    this.itemTarget.classList.add('has-scored-out-text');
    this.editFormTarget.insertAdjacentHTML('afterbegin', this.markedForDeletionField);
    this.editDeleteButtonListTarget.classList.add('is-hidden');
  }

  edit(e) {
    e.preventDefault();
    this.hideList();
    this.showForm();
  }

  cancel(e) {
    e.preventDefault();
    this.hideForm();
    this.showList();
  }

  save(e) {
    e.preventDefault();
    this.clearErrors();
    if (this.validateInputs()) {
      return;
    };
    this.startDateTextTarget.innerText = moment(this.startDateFieldInputTarget.value, 'YYYY-MM-DD').format('D/MM/YYYY');;
    const checkedUnit = this.dataUnitButtonTargets.find((button) => {
      return button.checked === true;
    })
    this.allowanceTextTarget.innerText = this.allowanceFieldInputTarget.value + checkedUnit.value;
    this.hideForm();
    this.showList();
    this.setUpForm();
  }

private
  hideList() {
    this.startDateTextTarget.classList.add('is-hidden');
    this.allowanceTextTarget.classList.add('is-hidden');
    this.editDeleteButtonListTarget.classList.add('is-hidden');
  }

  showList() {
    this.startDateTextTarget.classList.remove('is-hidden');
    this.allowanceTextTarget.classList.remove('is-hidden');
    this.editDeleteButtonListTarget.classList.remove('is-hidden');
  }

  showForm() {
    this.setUpForm();
    this.editFormTarget.classList.remove('is-hidden');
  }

  hideForm() {
    this.editFormTarget.classList.add('is-hidden');
  }

  setUpForm() {
    const currentDate = moment(this.startDateTextTarget.innerText, 'D/MM/YYYY').format('YYYY-MM-DD');
    this.flatpackerController.setPlaceHolder(currentDate);
    const allowanceUnit = this.allowanceTextTarget.innerText.match(this.letterRegex);
    const allowanceValue = parseFloat(this.allowanceTextTarget.innerText)
    this.allowanceFieldInputTarget.value = allowanceValue;
    this.dataUnitButtonTargets.forEach((button) => {
      if (button.value === allowanceUnit[0]) {
        button.checked = true;
      }
    })
  }

  validateInputs() {
    let emptyField = false;
    if (!this.startDateFieldInputTarget.value) {
      this.startDateFieldTarget.classList.add('has-error');
      this.startDateFieldTarget.insertAdjacentHTML('beforeend', this.error('Date cannot be blank'));
      emptyField = true;
    }

    if (!this.allowanceFieldInputTarget.value) {
      this.allowanceFieldTarget.classList.add('has-error');
      this.allowanceFieldTarget.insertAdjacentHTML('beforeend', this.error('Top-up amount cannot be blank'));
      emptyField = true;
    }
    return emptyField;
  }

  clearErrors() {
    const errors = document.querySelectorAll('.is-error-message');
    if (errors.length > 0) {
      errors.forEach((error) => {
        error.parentNode.classList.remove('has-error');
        error.remove();
      })
    }
  }

  error(message) {
    return `
      <span class="form-field-info is-error-message">
        ${message}
      </span>
    `
  }

  get markedForDeletionField() {
    return `
      <input type="hidden" name="device[top_ups][${this.id}][marked_for_deletion]" value="true">
    `
  }

  get flatpackerController() {
    const element = this.element.getElementsByTagName('input')[0];
    return this.application.getControllerForElementAndIdentifier(element, 'forms--datepicker')
  }

  get letterRegex() {
    return /[a-zA-Z]+/
  }
}
