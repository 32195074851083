import { Controller } from "stimulus"
import { dispatchEvent } from "../../helpers"

export default class extends Controller {
  static targets = ["amountField", "amountInput", "defaultUnitRadio", "yesRadio"]

  initialize() {
    this.showError();
  }

  resetFields() {
    this.defaultUnitRadioTarget.checked = true;
    this.amountInputTarget.value = this.amountInputTarget.getAttribute("data-default-value") || undefined;
  }

  private

    showError() {
      let hasError = this.amountFieldTarget.classList.contains("has-error");
      if(hasError) {
        this.yesRadioTarget.checked = true;
        dispatchEvent(this.yesRadioTarget, 'change');
      }
    }
}
