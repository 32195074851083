import { Controller } from "stimulus"

export default class extends Controller {
  
  toggleOpen(e) {
    e.preventDefault();
    ['open', 'close'].forEach((action) => {
      let toolTipController = this.getToolTipController(action);
      if(toolTipController) {
        toolTipController.toggleOpen();
      }
    });
  }

  private 

    getToolTipId(action) {
      return this.element.getAttribute(`data-tool-tip-${action}-id`);
    }

    getToolTipController(action) {
      let toolTipId = this.getToolTipId(action);
      let toolTip = document.querySelector(`[data-tool-tip="${toolTipId}"]`);
      return this.application.getControllerForElementAndIdentifier(toolTip, "ui--tool-tip");
    }
}

