import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    this.element.classList.remove("is-invisible");
    this.element.classList.add("is-open");
  }

  close() {
    this.element.classList.remove("is-open");
  }
}
