import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "inner" ]

  initialize() {
    this.marginTop = this.element.getAttribute("data-margin-top");
    this.resetInnerTargetWidth();
    this.watchForWindowResize();
    this.watchForWinowScroll();
    this.checkShouldStick();
  }

  private

    resetInnerTargetWidth() {
      this.innerTarget.style.maxWidth = this.element.offsetWidth + "px";
    }

    watchForWindowResize() {
      window.addEventListener('resize', ()  => {
        this.resetInnerTargetWidth();
        this.checkShouldStick();
      });
    }

    watchForWinowScroll() {
      window.addEventListener('scroll', ()  => {
        this.checkShouldStick();
      });
    }

    checkShouldStick() {
      let position = this.element.getBoundingClientRect();
      if(position.top < (0 + this.marginTop)) {
        this.innerTarget.style.top = this.marginTop + "px";
        this.element.style.height = this.element.offsetHeight + "px";
        this.innerTarget.classList.add("is-stuck");
      } else {
        this.innerTarget.classList.remove("is-stuck");
        this.element.style.height = "auto";
        this.innerTarget.style.top = "auto";
      }
    }
}
