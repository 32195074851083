import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["unhide", "switch"];

  password(e) {
    if (this.unhideTarget.type === "password") {
      this.unhideTarget.type = "text";
      this.switchTarget.textContent = "hide";
    } else {
      this.unhideTarget.type = "password";
      this.switchTarget.textContent = "show";
    }
  }
}
