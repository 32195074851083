import moment from "moment"

export const utcDateFormat = "YYYY-MM-DD HH:mm:ss";

export function humanFileSize(bytes, precision) {
  let thresh = 1000;
  if (bytes < thresh) return bytes + ' B';
  let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (bytes >= thresh);

  if(Math.round(bytes !== Number(bytes.toFixed(precision)))) {
    return bytes.toFixed(precision) + units[u];
  }
  return bytes + units[u];
}

export function dataValues(key, points) {
  return points.map(function (point) {
    let date = moment.utc(point.timestamp*1000).format(utcDateFormat);
    return {
      x: moment(date),
      y: point[key],
      shape: "circle"
    };
  });
}

export function renderAppUsageList(listElement, totalTextElement, dataUsageItems) {
  let fullTotal = 0;
  let appUsageListItemsHTML = `<p class="has-extra-small-text">No Data Available</p>`
  if (dataUsageItems.length > 0) {
    fullTotal = dataUsageItems.reduce((accumulator, dataUsageItem) => {
      return dataUsageItem.total + accumulator;
    }, 0);
    let highestDataUsageTotal = dataUsageItems[0].total;
    appUsageListItemsHTML = dataUsageItems.map((dataUsageItem) => {
      let percentage = (dataUsageItem.total / highestDataUsageTotal) * 100;
      return renderAppUsageItem(dataUsageItem.name, humanFileSize(dataUsageItem.total, 2), percentage)
    }).join('');
  }
  totalTextElement.innerText = humanFileSize(fullTotal, 2);
  listElement.innerHTML = appUsageListItemsHTML;
}

export function renderAppUsageItem(name, dataUsage, percentage) {
  return `
    <li class="has-extra-small-margin-bottom">
      <span class="is-display-flex has-space-between has-extra-small-text">
        <span>
          ${name}
        </span>
        <span>
          ${dataUsage}
        </span>
      </span>
      <div class="progress-bar is-secondary is-thin">
        <div class="progress-bar-progress" style="width: ${percentage}%"></div>
      </div>
    </li>
  `
}

export function showLoading(icon, chart) {
  chart.classList.add("is-invisible");
  icon.classList.remove("is-hidden");
  icon.classList.add("is-spinning");
}

export function hideLoading(icon, chart) {
  chart.classList.remove("is-invisible");
  icon.classList.add("is-hidden");
  icon.classList.remove("is-spinning");
}
