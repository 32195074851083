export function dispatchEvent(target, type) {
  if(typeof(Event) === 'function') {
    var event = new Event(type);
  } else {
    var event = document.createEvent('Event');
    event.initEvent(type, true, true);
  }
  setTimeout(() => {
    target.dispatchEvent(event);
  });
}

export function removeElement(element) {
  if(typeof element.remove=='function') {
    element.remove();
  }
  else {
    element.outerHTML = "";
  }
}

export function deleteFromArray(array, element) {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

export function listenForClickOffElement(element, callback) {
  document.addEventListener("click", (e) => {
    let targetElement = e.target;
    do {
      if(targetElement == element) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    callback();
  });
}
