import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['list'];

  onChange(e) {
    e.preventDefault();
    const data = { order: e.target.value };
    this.path = e.target.getAttribute('data-path');
    this.orderData(data);
  }

  private;
  orderData(data) {
    const url = this.path;
    let fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.listTarget.innerHTML = data;
      });
  }
}
