import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitButton"]

  onChange(e) {
    let value = e.currentTarget.value;
    this.submitButtonTarget.setAttribute('data-open-modal-id', value);
  }
}
