import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formStep", "wizardStep", "wizardStepLink", "previousStep", "nextStep", "alarmCreation", "alarmAssignment"]

  initialize() {
    this.resetSteps();
    this.showFormStepContent();
    this.initializeWizardSteps();
  }

  onClickWizardStep(e) {
    this.currentStepIndex = parseInt(e.currentTarget.getAttribute('data-index'));
    this.showFormStepContent();
    this.updateActiveWizardStep();
    this.validatorController.validate();
    if (this.currentStepIndex == 0) {
      this.hidePreviousStep();
    } else {
      this.showPreviousStep();
    }
  }

  onClickPreviousStep() {
    this.currentStepIndex -= 1;
    this.showFormStepContent();
    this.updateActiveWizardStep();
    if (this.currentStepIndex == 0) {
      this.hidePreviousStep();
    }
    this.validatorController.validate();
  }

  onClickNextStep(e) {
    if (this.wizardStepLinkTargets.length == this.currentStepIndex + 1) {
      return;
    } else {
      e.preventDefault()
    }
    this.completeCurrentStep();
    this.currentStepIndex += 1;
    this.showFormStepContent();
    if (this.currentStepIndex == 1) {
      this.showPreviousStep();
    }
    this.validatorController.validate();
    this.nextStepTarget.blur()
  }

  clearPlaceholder(e) {
    e.currentTarget.placeholder = "";
  }

  resetPlaceholder(e) {
    e.currentTarget.placeholder = e.currentTarget.getAttribute('data-placeholder-text');
  }

  private

  showFormStepContent() {
    this.showAlarmAssignmentOnLastStep();
    this.formStepTargets.forEach(formStep => {
      let showWhenIndex = formStep.getAttribute('data-show-when-wizard-index');
      if (showWhenIndex == this.currentStepIndex) {
        formStep.classList.remove('is-hidden');
      } else {
        formStep.classList.add('is-hidden');
      }
    });
  }

  initializeWizardSteps() {
    this.wizardStepTargets.forEach((step, stepIndex) => {
      if (stepIndex == this.currentStepIndex) {
        step.classList.add('is-active');
      } else {
        step.classList.remove('is-active');
        step.classList.add('is-disabled');
        this.wizardStepLinkTargets[stepIndex].setAttribute('disabled', 'disabled');
      }
    });
  }

  updateActiveWizardStep() {
    this.wizardStepTargets.forEach((step, stepIndex) => {
      if(stepIndex == this.currentStepIndex) {
        step.classList.add('is-active');
      } else {
        step.classList.remove('is-active');
      }
    });
  }

  completeCurrentStep() {
    this.wizardStepTargets.forEach((step, stepIndex) => {
      if (stepIndex == this.currentStepIndex) {
        step.classList.add('is-complete');
        step.classList.remove('is-active');
      }
      else if (stepIndex == this.currentStepIndex + 1) {
        this.wizardStepLinkTargets[stepIndex].removeAttribute('disabled', 'disabled');
        step.classList.remove('is-disabled');
        step.classList.add('is-active');
      }
    });
  }

  hidePreviousStep() {
    this.previousStepTarget.classList.add('is-hidden');
  }

  showPreviousStep() {
    this.previousStepTarget.classList.remove('is-hidden');
  }

  resetSteps() {
    this.currentStepIndex = 0;
    this.hidePreviousStep();
  }

  showAlarmAssignmentOnLastStep() {
    let nextStepTextElement = this.nextStepTarget.querySelector('span');
    if (this.currentStepIndex == this.wizardStepLinkTargets.length - 1) {
      this.alarmCreationTarget.classList.add('is-hidden')
      this.alarmAssignmentTarget.classList.remove('is-hidden')
      nextStepTextElement.textContent = nextStepTextElement.getAttribute('data-finish-step-text')
    } else {
      this.alarmCreationTarget.classList.remove('is-hidden')
      this.alarmAssignmentTarget.classList.add('is-hidden')
      nextStepTextElement.textContent = nextStepTextElement.getAttribute('data-next-step-text')
    }
  }

  get validatorController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "forms--validator");
  }
}
