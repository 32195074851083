import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';
import c3 from 'c3';
import 'c3/c3.min.css';
import { utcDateFormat, showLoading, hideLoading } from './helpers';
import { removeElement } from '../../helpers';

export default class extends Controller {
  static targets = ['chart', 'legend', 'loadingIcon', 'monthSelect'];

  initialize() {
    this.colors = [
      '#07AA17',
      '#FF9100',
      '#F50057',
      '#60D9EA',
      '#8686B2',
      '#B2B4CA',
      '#83D48B',
      '#FFC77E',
      '#FF91B8',
      '#A8F4FF',
      '#C5C5F1',
      '#6295B5',
      '#FF9E82',
      '#FFEC82',
      '#6DD66D'
    ];
    this.fetchData();
  }

  onChangeMonthSelect() {
    this.removeLegend();
    this.fetchData();
  }

  private;

  fetchData() {
    showLoading(this.loadingIconTarget, this.chartTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = `dashboard/roaming_countries.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        if (Object.keys(data).length === 0) {
          this.displayNoData();
        } else {
          this.generateChart(data);
          this.generateLegend(data);
        }
      });
  }

  generateChart(data) {
    const chart = c3.generate({
      size: {
        height: 265
      },
      data: {
        columns: Object.entries(data),
        type: 'pie'
      },
      pie: {
        expand: false,
        label: {
          show: false
        }
      },
      color: {
        pattern: this.colors
      },
      transition: {
        duration: 0
      },
      interaction: {
        enabled: false
      },
      legend: {
        show: false
      },
      bindto: this.chartTarget
    });

    chart.load({
      done: () => {
        hideLoading(this.loadingIconTarget, this.chartTarget);
      }
    });
  }

  generateLegend(data) {
    const legend = `
      <ul class="pie-chart-key has-small-margin-bottom" data-target="charts--roaming-countries.legend">
        ${this.renderLegendTemplate(data)}
      </ul>`;
    this.chartTarget.insertAdjacentHTML('beforebegin', legend);
  }

  renderLegendTemplate(data) {
    return Object.keys(data)
      .map((key, index) => {
        return `
          <li class="pie-chart-key-item">
            <div class="pie-chart-key-item-color" style="background-color: ${
              this.colors[index]
            };"></div>
            <span class="has-strong-text has-uppercase-text has-tiny-text">${key}</span>
          </li>`;
      })
      .join('');
  }

  removeLegend() {
    if (this.hasLegendTarget) {
      removeElement(this.legendTarget);
    }
  }

  displayNoData() {
    hideLoading(this.loadingIconTarget, this.chartTarget);
    const text =
      '<p class="has-pale-text has-center-aligned-text">No Data Available</p>';
    this.chartTarget.innerHTML = text;
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
