import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formContainer", "fileName", "progressBarContainer", "progress", "submitButton", "serverErrorMessage", "nextButton"]

  onFileChange(e) {
    let files = e.currentTarget.files;
    if(files.length) {
      this.showProgress(0);
      let fileName = files[0].name;
      this.fileNameTarget.innerHTML = fileName;
      this.fileNameTarget.classList.remove("is-hidden");
      this.progressBarContainerTarget.classList.remove("is-hidden");
    } else {
      this.fileNameTarget.innerHTML = "";
      this.fileNameTarget.classList.add("is-hidden");
      this.progressBarContainerTarget.classList.add("is-hidden");
    }
  }

  onSubmit() {
    this.showProcessing();
  }

  onAjaxProgress(e) {
    let [xhr] = e.detail;
    let upload = xhr.upload;
    if(!upload) return;
    upload.addEventListener("progress", this.progressHandler.bind(this), false);
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.formContainerTarget.innerHTML = xhr.response;
    this.enableNextButton();
  }

  onAjaxError(e) {
    let [data, status, xhr] = e.detail;
    const notAcceptedStatusCode = 406;
    if(xhr.status == notAcceptedStatusCode) {
      this.formContainerTarget.innerHTML = xhr.response;
    } else {
      this.showServerError(status);
    }
  }

  private

    progressHandler(e) {
      var percent = (e.loaded / e.total) * 100;
      this.showProgress(percent);
    }

    showProgress(percent) {
      this.progressTarget.style.width = percent + "%";
    }

    showServerError(statusText) {
      this.hideProcessing();
      this.serverErrorMessageTarget.classList.remove("is-hidden");
      this.serverErrorMessageTarget.innerHTML = statusText;
    }

    showProcessing() {
      this.submitButtonTarget.classList.add('is-loading');
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }

    hideProcessing() {
      this.submitButtonTarget.classList.remove('is-loading');
      this.submitButtonTarget.removeAttribute('disabled');
    }

    enableNextButton() {
      if(!this.nextButtonTarget) return;
      this.nextButtonTarget.removeAttribute("disabled");
    }
}
