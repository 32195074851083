import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    this.element.classList.add('is-open');
  }

  close(e) {
    if(e) { e.preventDefault() };
    this.element.classList.remove('is-open');
  }

  isOpen() {
    return this.element.classList.contains('is-open');
  }
}
