import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import c3 from 'c3';
import 'c3/c3.min.css';
import moment from 'moment';
import {
  utcDateFormat,
  humanFileSize,
  renderAppUsageList,
  showLoading,
  hideLoading
} from './helpers';

export default class extends Controller {
  static targets = [
    'monthSelect',
    'chart',
    'appUsageList',
    'totalText',
    'loadingIcon',
    'individualUsageKey',
    'groupUsageKey',
    'topUpUsageKey',
    'previousLimitKey'
  ];

  initialize() {
    this.deviceId = this.element.getAttribute('data-device-id');
    this.monthlyDataAllowance = this.element.getAttribute(
      'data-monthly-data-allowance'
    );
    this.monthlyDataLimit = this.element.getAttribute(
      'data-monthly-data-limit'
    );
    this.appView = this.element.getAttribute('data-app-view');
    this.fetchAverageDailyData();
  }

  onChangeMonthSelect() {
    this.fetchAverageDailyData();
  }

  onClickAverageDataTab() {
    this.dailyUsageController.hide();
    this.monthlyUsageController.show();
  }

  private;

  buildFetchDataURL() {
    if (this.appView == 'yes') {
      return `/mobile/average_usage.json?start_date=${this.startDate.format(
        utcDateFormat
      )}&end_date=${this.endDate.format(utcDateFormat)}`;
    }
    return `/devices/${
      this.deviceId
    }/average_usage.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
  }

  fetchAverageDailyData() {
    showLoading(this.loadingIconTarget, this.chartTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };

    let url = this.buildFetchDataURL();
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.generateChart(data);
        if (data.is_android) {
          renderAppUsageList(
            this.appUsageListTarget,
            this.totalTextTarget,
            data.app_usage
          );
          this.monthlyUsageController.renderAppUsageList(data.app_usage);
        }
      });
  }

  generateChart(data) {
    this.calculateChartData(data);

    let x = ['x'].concat(this.days);
    let yIndividual = ['Individual Data Usage'].concat(this.individualUsage);

    let yCurrentAverageLimit = ['Current Average Usage Limit'].concat(
      this.currentAverageLimit
    );
    let yPreviousAverageLimit = ['Previous Average Usage Limit'].concat(
      this.previousAverageLimit
    );

    let yMonthlyLimit = ['Monthly Data Limit'];
    this.days.forEach(() => {
      yMonthlyLimit.push(this.monthlyDataLimitValue);
    });
    let yGroup = ['Group Data Usage'].concat(this.groupUsage);
    let yTopUp = ['Top Up Data Usage'].concat(this.topUpUsage);
    let yExceeded = ['Exceeding Usage'].concat(this.exceedingDataUsage);

    let chart = c3.generate({
      size: {
        height: 300
      },
      padding: {
        top: 5
      },
      data: {
        x: 'x',
        columns: [
          x,
          yIndividual,
          yCurrentAverageLimit,
          yMonthlyLimit,
          yGroup,
          yTopUp,
          yExceeded,
          yPreviousAverageLimit
        ],
        type: 'bar',
        types: {
          'Current Average Usage Limit': 'line',
          'Monthly Data Limit': 'line',
          'Previous Average Usage Limit': 'line'
        },
        colors: {
          'Current Average Usage Limit': '#E54253',
          'Monthly Data Limit': '#F9B226',
          'Previous Average Usage Limit': '#A4A6B6',
          'Individual Data Usage': '#73CFDE',
          'Group Data Usage': '#10517B',
          'Top Up Data Usage': '#8DBF22',
          'Exceeding Usage': '#E54253'
        },
        groups: [
          [
            'Individual Data Usage',
            'Top Up Data Usage',
            'Group Data Usage',
            'Exceeding Usage'
          ]
        ],
        order: [
          'Individual Data Usage',
          'Top Up Data Usage',
          'Group Data Usage',
          'Exceeding Usage'
        ]
      },
      axis: {
        x: {
          padding: {
            left: 0
          },
          height: 45,
          label: {
            text: this.getMonthText(),
            position: 'outer-center'
          },
          tick: {
            culling: false,
            format: '%-d',
            outer: false
          },
          type: 'timeseries'
        },
        y: {
          tick: {
            format: (d) => {
              return humanFileSize(d, 2);
            },
            outer: false
          }
        }
      },
      bar: {
        width: {
          ratio: 0.9
        }
      },
      grid: {
        x: {
          lines: this.topUpDates
        },
        focus: {
          show: false
        },
        lines: {
          front: false
        }
      },
      legend: {
        show: false
      },
      point: {
        focus: {
          expand: {
            r: 4
          }
        },
        r: 0,
        show: true
      },
      tooltip: {
        contents: (usageData) => {
          return this.tooltipTemplate(usageData);
        }
      },
      transition: {
        duration: 0
      },
      bindto: this.chartTarget
    });

    chart.load({
      done: () => {
        this.addZeroValueBarLines();
        hideLoading(this.loadingIconTarget, this.chartTarget);
        this.updateKey(
          this.individualUsage,
          this.groupUsage,
          this.previousAverageLimit
        );
      }
    });
  }

  calculateChartData(data) {
    let noDaysInPeriod = this.endDate.diff(this.startDate, 'days');
    this.topUps = data.top_ups;
    this.topUpDates = [];
    this.topUps.forEach((topUp) => {
      if (topUp.changed_at) {
        this.topUpDates.push({
          value: moment(topUp.changed_at).startOf('day')
        });
      }
    });
    this.days = [];
    this.calculateAverageLimits(data);
    this.individualUsage = [];
    this.groupUsage = [];
    this.topUpUsage = [];
    this.exceedingDataUsage = [];
    this.runningTotal = 0;
    this.individualDataAllowance = data.individual_data_allowance;
    let today = moment().endOf('day');
    this.monthlyDataLimitValue = (
      this.monthlyDataAllowance * this.monthlyDataLimit
    ).toFixed();

    for (let i = 0; i < noDaysInPeriod + 1; i++) {
      this.day = moment(this.startDate).add(i, 'days');
      this.days.push(this.day);
      this.topUpAmount = this.calculateTopUpAmount(this.topUps, this.day);
      this.runningTotal += data.usage[i].y || 0;
      if (this.day.isAfter(today)) {
        this.individualUsage.push(null);
        this.groupUsage.push(null);
        this.exceedingDataUsage.push(null);
        this.topUpUsage.push(null);
      } else {
        this.calculateLowestLimit(i);
        if (!this.totalUsageExceedsLimits) {
          this.exceedingDataUsage.push(0);
          if (!this.totalUsageExceedsIndividualAllowance) {
            this.individualUsage.push(this.runningTotal);
            this.groupUsage.push(0);
            this.topUpUsage.push(0);
          } else if (this.noCurrentTopUp) {
            this.individualUsage.push(this.individualDataAllowance);
            this.groupUsage.push(
              this.runningTotal - this.individualDataAllowance
            );
            this.topUpUsage.push(0);
          } else {
            if (!this.totalUsageExceedsTopUpAndIndividualAllowance) {
              this.individualUsage.push(this.individualDataAllowance);
              this.groupUsage.push(0);
              this.topUpUsage.push(
                this.runningTotal - this.individualDataAllowance
              );
            } else {
              this.individualUsage.push(this.individualDataAllowance);
              this.groupUsage.push(
                this.runningTotal -
                  (this.topUpAmount + this.individualDataAllowance)
              );
              this.topUpUsage.push(this.topUpAmount);
            }
          }
        } else {
          this.exceedingDataUsage.push(
            this.runningTotal - this.lowestDataValue
          );
          if (this.individualUsageExceedsIndividualAllowance) {
            this.individualUsage.push(this.lowestDataValue);
            this.groupUsage.push(0);
            this.topUpUsage.push(0);
          } else if (this.noCurrentTopUp) {
            this.individualUsage.push(this.individualDataAllowance);
            this.groupUsage.push(
              this.lowestDataValue - this.individualDataAllowance
            );
            this.topUpUsage.push(0);
          } else {
            if (this.topUpAndIndividualUsageExceedsLimits) {
              this.individualUsage.push(this.individualDataAllowance);
              this.groupUsage.push(0);
              this.topUpUsage.push(
                this.lowestDataValue - this.individualDataAllowance
              );
            } else {
              this.individualUsage.push(this.individualDataAllowance);
              this.groupUsage.push(
                this.lowestDataValue -
                  (this.individualDataAllowance + this.topUpAmount)
              );
              this.topUpUsage.push(this.topUpAmount);
            }
          }
        }
      }
    }
  }

  calculateAverageLimits(data) {
    this.previousAverageLimit = [];
    this.currentAverageLimit = [];
    if (this.noTopUps) {
      data.current_average_limits.forEach((limit) => {
        this.currentAverageLimit.push(limit.y.toFixed(2));
      });
      data.current_average_limits.forEach(() => {
        this.previousAverageLimit.push(null);
      });
    } else {
      const latestTopUpDay = moment(this.topUpDates[0].value).get('date');
      const latestTopUpDate = moment(this.topUpDates[0].value);
      let latestTopUpStarted = false;
      if (latestTopUpDate.isBefore(this.startDate)) {
        latestTopUpStarted = true;
      }
      data.current_average_limits.forEach((limit) => {
        if (limit.x == latestTopUpDay) {
          latestTopUpStarted = true;
        }
        if (latestTopUpStarted) {
          this.currentAverageLimit.push(limit.y.toFixed(2));
        } else {
          this.currentAverageLimit.push(null);
        }
      });
      if (data.original_average_limits != null) {
        data.original_average_limits.forEach((limit) => {
          this.previousAverageLimit.push(limit.y.toFixed(2));
        });
      }
    }
  }

  calculateLowestLimit(day) {
    this.averageLimit = 0;
    if (this.noTopUps || !this.day.isBefore(this.topUpDates[0].value)) {
      this.averageLimit = this.currentAverageLimit[day];
    } else {
      this.averageLimit = this.previousAverageLimit[day];
    }
    this.lowestDataValue = Math.min(
      this.averageLimit,
      this.monthlyDataLimitValue
    );
  }

  addZeroValueBarLines() {
    let zeroValueBarClasses = [];
    d3.selectAll('.c3-event-rect').classed('zero-data-bar', (d, index) => {
      if (d.value === 0) {
        zeroValueBarClasses.push('.c3-bar-' + index);
        return d;
      }
    });

    for (let i = 0; i < zeroValueBarClasses.length; i++) {
      d3.select(zeroValueBarClasses[i]).attr('style', 'stroke-width: 2px');
    }
  }

  updateKey(individual, group, previousDataLimit) {
    if (individual.some(this.greaterThanZero)) {
      this.individualUsageKeyTarget.classList.remove('is-hidden');
    } else {
      this.individualUsageKeyTarget.classList.add('is-hidden');
    }
    if (group.some(this.greaterThanZero)) {
      this.groupUsageKeyTarget.classList.remove('is-hidden');
    } else {
      this.groupUsageKeyTarget.classList.add('is-hidden');
    }
    if (this.noTopUps) {
      this.topUpUsageKeyTarget.classList.add('is-hidden');
    } else {
      this.topUpUsageKeyTarget.classList.remove('is-hidden');
    }
    if (previousDataLimit.some(this.greaterThanZero)) {
      this.previousLimitKeyTarget.classList.remove('is-hidden');
    } else {
      this.previousLimitKeyTarget.classList.add('is-hidden');
    }
  }

  greaterThanZero(n) {
    return n > 0;
  }

  calculateTopUpAmount(topUps, day) {
    let currentTopUp =
      topUps.find((topUp) => {
        return !day.isBefore(moment(topUp.changed_at).startOf('day'));
      }) || 0;
    return currentTopUp.data_allowance || 0;
  }

  getMonthText() {
    return this.monthSelectTarget.options[this.monthSelectTarget.selectedIndex]
      .label;
  }

  tooltipTemplate(usageData) {
    return `
        <div class="has-tiny-text">
          <div class="is-display-flex has-space-between has-tiny-margin-bottom">
            <span class="has-strong-text has-uppercase-text">
              Average Data Usage Allowance
            </span>
            <span class="has-strong-text has-small-margin-left">
              ${this.getCurrentAverageLimit(usageData)}
            </span>
          </div>
          ${this.usageBreakdown(usageData)}
        </div>
      `;
  }

  getCurrentAverageLimit(usageData) {
    const currentDay = moment(usageData[0].x).startOf('day');
    if (this.noTopUps || !this.topUpDates[0].value.isAfter(currentDay)) {
      return `${humanFileSize(usageData[1].value || 0, 2)}`;
    } else {
      return `${humanFileSize(usageData[6].value || 0, 2)}`;
    }
  }

  usageBreakdown(usageData) {
    const totalUsage = this.totalUsage(usageData);
    const currentDay = moment(usageData[0].x).startOf('day');
    const currentTopUpAmount = this.calculateTopUpAmount(
      this.topUps,
      currentDay
    );
    const individualUsage = Math.min(this.individualDataAllowance, totalUsage);
    let topUpUsage = 0;
    let groupUsage = 0;
    let remainder = totalUsage - individualUsage;
    if (remainder > 0) {
      topUpUsage = Math.min(currentTopUpAmount, remainder);
      remainder -= topUpUsage;
      if (remainder > 0) {
        groupUsage = remainder;
      }
    }
    return this.generateBreakdownTemplate(
      totalUsage,
      individualUsage,
      topUpUsage,
      groupUsage
    );
  }

  generateBreakdownTemplate(
    totalUsage,
    individualUsage,
    topUpUsage,
    groupUsage
  ) {
    let template = '';
    if (totalUsage > 0) {
      template += this.dataUsageTemplate('Total data usage', totalUsage);
    }
    if (individualUsage > 0) {
      template += this.dataUsageTemplate(
        'Individual Data Usage',
        individualUsage
      );
    }
    if (topUpUsage > 0) {
      template += this.dataUsageTemplate('Top Up Usage', topUpUsage);
    }
    if (groupUsage > 0) {
      template += this.dataUsageTemplate('Group Data Usage', groupUsage);
    }
    return template;
  }

  totalUsage(usageData) {
    const individualData = usageData[0];
    const groupData = usageData[3];
    const topUpData = usageData[4];
    const exceededAllowance = usageData[5];
    return (
      individualData.value +
      groupData.value +
      topUpData.value +
      exceededAllowance.value
    );
  }

  dataUsageTemplate(label, dataUsage) {
    return `
        <div class="is-display-flex has-space-between">
          <span>
            ${label}
          </span>
          <span class="has-small-margin-left">
            ${humanFileSize(dataUsage, 2)}
          </span>
        </div>
      `;
  }

  get noTopUps() {
    return this.topUpDates.length === 0;
  }

  get totalUsageExceedsLimits() {
    return this.runningTotal > this.lowestDataValue;
  }

  get totalUsageExceedsIndividualAllowance() {
    return this.runningTotal > this.individualDataAllowance;
  }

  get noCurrentTopUp() {
    return this.topUpAmount === 0;
  }

  get totalUsageExceedsTopUpAndIndividualAllowance() {
    return this.topUpAmount < this.runningTotal - this.individualDataAllowance;
  }

  get individualUsageExceedsIndividualAllowance() {
    return this.lowestDataValue < this.individualDataAllowance;
  }

  get topUpAndIndividualUsageExceedsLimits() {
    return (
      this.topUpAmount + this.individualDataAllowance > this.lowestDataValue
    );
  }

  get monthlyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--monthly-usage'
    );
  }

  get dailyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--daily-usage'
    );
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
