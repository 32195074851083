import { Controller } from "stimulus"

export default class extends Controller {
  openModal(e) {
    e.preventDefault();
    if(!this.modalController) { return }
    if(this.modalController.isOpen()) {
      if(this.openableButton) {
        this.openableButton.classList.remove('is-open');
        this.modalElement.classList.replace('is-open', 'is-closed');
      }
      this.modalController.close();
    } else {
      if(this.openableButton) {
        this.openableButton.classList.add('is-open');
        this.modalElement.classList.replace('is-closed', 'is-open');
      }
      if(this.floatingActionButtonController) {
        this.floatingActionButtonController.dismiss()
      }
      this.modalController.open();
    }
  }

  get modalController() {
    let modalId = this.element.getAttribute('data-open-modal-id');
    let modalElement = document.querySelector(`[data-modal-id="${modalId}"]`);
    return this.application.getControllerForElementAndIdentifier(modalElement, "ui--modal");
  }

  get openableButton() {
    let buttonId = this.element.getAttribute('data-target-button-id');
    let buttonElement = document.querySelector(`[data-button-id="${buttonId}"]`);
    return buttonElement;
  }

  get modalElement() {
    let modalId = this.element.getAttribute('data-open-modal-id');
    let modalElement = document.querySelector(`[data-element-id="${modalId}"]`);
    return modalElement;
  }

  get floatingActionButtonController() {
    let fabId = this.element.getAttribute('data-dismissable-fab-id');
    let fabElement = document.querySelector(`[data-fab-id="${fabId}"]`);
    return this.application.getControllerForElementAndIdentifier(fabElement, "ui--floating-action-button");
  }
}
