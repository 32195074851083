import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/light.css";
import moment from "moment"

export default class extends Flatpickr {
  clear() {
    this.fp.clear()
  }

  setPlaceHolder(date) {
    this.fp.setDate(date)
  }

  get minDate() {
    return moment(this.fp.config.minDate);
  }

  get maxDate() {
    return moment(this.fp.config.maxDate);
  }
}
