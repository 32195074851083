import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.defaultPath = this.element.getAttribute("data-default-path");
    this.registerInterestPath = this.element.getAttribute("data-register-interest-path");
  }

  onCountryChange(e) {
    let select = e.currentTarget;
    let selectedIndex = select.selectedIndex;
    if(selectedIndex == 0) {
      this.hideRegisterInterest();
      return;
    }
    let country = select.options[selectedIndex].text;
    if(country == "United Kingdom") {
      this.hideRegisterInterest();
    } else {
      this.showRegisterInterest();
    }
    this.validatorController.validate();
  }

  private

    showRegisterInterest() {
      this.element.action = this.registerInterestPath;
      this.toggleContent("show");
    }

    hideRegisterInterest() {
      this.element.action = this.defaultPath;
      this.toggleContent("hide");
    }

    toggleContent(action) {
      this.contentTargets.forEach(content => {
        let whenRegisterInterest = content.getAttribute("data-when-register-interest");
        if(whenRegisterInterest == action) {
          this.show(content);
        }
        else {
          this.hide(content);
        }
      });
    }

    show(content) {
      let mobileRule = content.getAttribute("data-mobile-rule");
      if(mobileRule) {
        content.classList.add(mobileRule);
      } else {
        content.classList.remove('is-hidden');
      }
    }

    hide(content) {
      let mobileRule = content.getAttribute("data-mobile-rule");
      if(mobileRule) {
        content.classList.remove(mobileRule);
      } else {
        content.classList.add('is-hidden');
      }
    }

    get validatorController() {
      return this.application.getControllerForElementAndIdentifier(this.element, "forms--validator");
    }
}
