import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loadingIcon"]

  onSubmit(e) {
    this.loadingIconTarget.classList.add('is-spinning');
    this.loadingIconTarget.classList.remove('is-invisible');
    e.target.classList.add('is-invisible');
  }
}
