import { Controller } from "stimulus"
import { removeElement } from "../../helpers"

export default class extends Controller {
  static targets = ["realInput", "fakeInput", "fakeItem"]

  initialize() {
    this.items = [];
    this.itemIncement = 0;
    this.placeholderText = this.element.getAttribute("data-placeholder");
    this.renderTemplate();
    this.renderInitialItems();
  }

  onKeyDown(e) {
    switch (e.key) {
      case ",":
        e.preventDefault();
        this.onPressAdd()
        break;
      case "Enter":
        e.preventDefault();
        this.onPressAdd()
        break;
    }
  }

  onPressAdd() {
    const value = this.fakeInputTarget.value;
    this.addItem(value);
  }

  onClickRemove(e) {
    e.preventDefault();
    const itemId = e.currentTarget.getAttribute("data-id");
    this.removeItem(itemId)
  }

  focusOnInput() {
    this.fakeInputTarget.focus();
  }

  onBlur() {
    this.onPressAdd();
  }

  private

    addItem(value) {
      if(!value) return;
      let valueHasCharacters = value.trim() != "";
      if(valueHasCharacters) {
        this.itemIncement++;
        let newItem = {
          id: this.itemIncement,
          value: value
        };
        this.items.push(newItem)
        this.renderItem(newItem);
      }

      this.resetFakeInput();
      this.refreshRealInput();
      this.updatePlaceholder();
    }

    removeItem(itemId) {
      const itemElement = this.fakeItemTargets.find(fakeItem => {
        return fakeItem.getAttribute('data-id') == itemId;
      });
      if(!itemElement) return;
      for(var i = this.items.length; i--;) {
        if (this.items[i].id == itemId) this.items.splice(i, 1);
      }
      removeElement(itemElement);
      this.refreshRealInput();
      this.updatePlaceholder();
    }

    resetFakeInput() {
      this.fakeInputTarget.value = "";
    }

    refreshRealInput() {
      const value = this.items.map(item => item['value']);
      this.realInputTarget.value = value;
    }

    updatePlaceholder() {
      if(this.items.length > 0) {
        this.fakeInputTarget.placeholder = "";
      } else {
        this.fakeInputTarget.placeholder = this.placeholderText;
      }
    }

    renderTemplate() {
      const template = `
        <div class="form-field-list-fake" data-action="click->forms--list#focusOnInput">
          <input type="text" class="form-field-list-fake-input" placeholder="${this.placeholderText}" data-action="keydown->forms--list#onKeyDown change->forms--list#onBlur" data-target="forms--list.fakeInput">
        </div>
      `;
      this.element.insertAdjacentHTML('beforeend', template);
    }

    renderInitialItems() {
      let initialValues = this.element.getAttribute("data-initial-items")
      if (!initialValues) return
      let initialItems = JSON.parse(initialValues)
      initialItems.forEach((item) => {
        this.addItem(item);
      });
    }

    renderItem(item) {
      const crossSvg = `
        <svg viewBox="0 0 18 17" aria-hidden="true" class="has-stroke">
          <g stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
            <g transform="translate(-256.000000, -9.000000)" stroke-width="5">
              <g transform="translate(257.966125, 10.000000)">
                <path d="M0.383112981,0.890174279 L14.2292668,14.3577224"></path>
                <path d="M0.383112981,14.3577224 L14.2292668,0.890174279"></path>
              </g>
            </g>
          </g>
        </svg>
        `;
      const template = `
        <div class="form-field-list-fake-item" data-target="forms--list.fakeItem" data-id="${item.id}">
          ${item.value}
          <i class="form-field-list-fake-item-remove" data-action="click->forms--list#onClickRemove" data-id="${item.id}">
            ${crossSvg}
          </i>
        </div>
      `
      this.fakeInputTarget.insertAdjacentHTML('beforebegin', template);
    }
}
