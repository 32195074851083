import { Controller } from "stimulus"

export default class extends Controller {
  onClickButton(e) {
    e.preventDefault();
    this.scrollToElement();
  }

  private

    scrollToElement() {
      let elementPosition = this.element.getBoundingClientRect();
      let headerOffset = this.calculateHeaderOffset();
      let targetY = (elementPosition.top + window.scrollY) - headerOffset;
      window.scrollTo(0, targetY);
    }

    calculateHeaderOffset() {
      let header = document.querySelector(".header");
      if(!header || getComputedStyle(header).position != "fixed") return 0;
      return header.offsetHeight;
    }
}
