import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item" ]
  
  connect() {
    this.element.classList.add("is-active");
  }

  closeItem(e) {
    let closeButton = e.currentTarget;
    let index = closeButton.getAttribute('data-index');
    let item = this.itemTargets[index];
    item.classList.add("is-read");
    setTimeout(() => {
      item.classList.add("is-hidden");
    }, 500);
  }
}
