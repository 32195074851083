import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["assignDeviceSwitch", "submitButton"]

  initialize() {
    this.submitEnabled = false
    this.submitButtonTarget.setAttribute('disabled', 'disabled')
    console.log(this.submitEnabled)
  }

  enableSubmit() {
    if (this.submitEnabled) {
      console.log('do nothing')
      return;
    }
    this.submitButtonTarget.removeAttribute('disabled');
    console.log('enabled')
    this.submitEnabled = true
  }
}
