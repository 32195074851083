import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import c3 from 'c3';
import 'c3/c3.min.css';
import moment from 'moment';
import {
  utcDateFormat,
  humanFileSize,
  showLoading,
  hideLoading
} from './helpers';

export default class extends Controller {
  static targets = [
    'monthSelect',
    'chart',
    'appUsageList',
    'totalText',
    'loadingIcon'
  ];

  initialize() {
    this.monthlyDataAllowance = this.element.getAttribute(
      'data-monthly-data-allowance'
    );
    this.monthlyDataLimit = this.element.getAttribute(
      'data-monthly-data-limit'
    );
    this.totalDevicesInGroup = this.element.getAttribute(
      'data-total-devices-in-group'
    );
    this.noDataMessage = this.element.getAttribute('data-no-data-message');
    this.fetchAverageDailyData();
  }

  onChangeMonthSelect() {
    this.fetchAverageDailyData();
  }

  onClickAverageDataTab() {
    this.monthlyUsageController.show();
  }

  private;

  buildFetchDataURL() {
    return `/dashboard/average_usage.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
  }

  fetchAverageDailyData() {
    showLoading(this.loadingIconTarget, this.chartTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = this.buildFetchDataURL();
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.generateChart(data);
      });
  }

  generateChart(data) {
    this.calculateChartData(data);
    let x = ['x'].concat(this.days);
    let y1 = ['Actual Data Usage'].concat(this.usage);
    let y2 = ['Average Usage Limit'].concat(
      data.average_limits.map((d) => {
        return d.y.toFixed(2);
      })
    );

    let y3 = ['Monthly Data Limit'];
    data.average_limits.forEach(() => {
      y3.push(this.monthlyDataLimitValue);
    });
    let y4 = ['Exceeding Usage'].concat(this.exceedingDataUsage);
    var columns = [x, y1, y2, y3, y4];
    if (this.totalDevicesInGroup == 0) {
      columns = [];
    }
    let chart = c3.generate({
      size: {
        height: 300
      },
      padding: {
        top: 5
      },
      data: {
        x: 'x',
        columns: columns,
        type: 'bar',
        types: {
          'Actual Data Usage': 'bar',
          'Average Usage Limit': 'line',
          'Monthly Data Limit': 'line'
        },
        colors: {
          'Actual Data Usage': '#10517B',
          'Exceeding Usage': '#E54253',
          'Average Usage Limit': '#E54253',
          'Monthly Data Limit': '#F9B226'
        },
        groups: [['Actual Data Usage', 'Exceeding Usage']],
        order: ['Actual Data Usage', 'Exceeding Usage'],
        empty: { label: { text: this.noDataMessage } }
      },
      axis: {
        x: {
          padding: {
            left: 0
          },
          height: 45,
          label: {
            text: this.getMonthText(),
            position: 'outer-center'
          },
          tick: {
            culling: false,
            format: '%-d',
            outer: false
          },
          type: 'timeseries'
        },
        y: {
          tick: {
            format: (d) => {
              return humanFileSize(d, 2);
            },
            outer: false
          }
        }
      },
      bar: {
        width: {
          ratio: 0.9
        }
      },
      grid: {
        focus: {
          show: false
        }
      },
      legend: {
        show: false
      },
      point: {
        focus: {
          expand: {
            r: 4
          }
        },
        r: 0,
        show: true
      },
      tooltip: {
        contents: (usageData) => {
          return this.tooltipTemplate(usageData);
        }
      },
      transition: {
        duration: 0
      },
      bindto: this.chartTarget
    });

    chart.load({
      done: () => {
        this.addZeroValueBarLines();
        hideLoading(this.loadingIconTarget, this.chartTarget);
      }
    });
  }

  calculateChartData(data) {
    let noDaysInPeriod = this.endDate.diff(this.startDate, 'days');
    this.days = [];
    this.usage = [];
    this.exceedingDataUsage = [];
    let runningTotal = 0;
    let today = moment().endOf('day');
    this.monthlyDataLimitValue = (
      this.monthlyDataAllowance * this.monthlyDataLimit
    ).toFixed();

    for (let i = 0; i < noDaysInPeriod + 1; i++) {
      let day = moment(this.startDate).add(i, 'days');
      this.days.push(day);
      runningTotal += data.usage[i].y || 0;
      if (!day.isBefore(today)) {
        this.usage.push(null);
        this.exceedingDataUsage.push(null);
      } else {
        const lowestDataValue = Math.min(
          data.average_limits[i].y,
          this.monthlyDataLimitValue
        );
        if (runningTotal < lowestDataValue) {
          this.usage.push(runningTotal);
          this.exceedingDataUsage.push(0);
        } else {
          this.usage.push(lowestDataValue);
          this.exceedingDataUsage.push(runningTotal - lowestDataValue);
        }
      }
    }
  }

  addZeroValueBarLines() {
    let zeroValueBarClasses = [];
    d3.selectAll('.c3-event-rect').classed('zero-data-bar', (d, index) => {
      if (d.value === 0) {
        zeroValueBarClasses.push('.c3-bar-' + index);
        return d;
      }
    });

    for (let i = 0; i < zeroValueBarClasses.length; i++) {
      d3.select(zeroValueBarClasses[i]).attr('style', 'stroke-width: 2px');
    }
  }

  getMonthText() {
    return this.monthSelectTarget.options[this.monthSelectTarget.selectedIndex]
      .label;
  }

  tooltipTemplate(usageData) {
    return `
        <div class="has-tiny-text">
          <div class="is-display-flex has-space-between has-tiny-margin-bottom">
            <span class="has-strong-text has-uppercase-text">
              Average Usage Limit
            </span>
            <span class="has-strong-text has-small-margin-left">
              ${humanFileSize(usageData[1].value || 0, 2)}
            </span>
          </div>
          ${this.actualDataUsageTemplate(usageData)}
        </div>
      `;
  }

  actualDataUsageTemplate(usageData) {
    const groupData = usageData[0];
    const exceededAllowance = usageData[3];
    if (!groupData.value && !exceededAllowance.value) {
      return '';
    }
    const total = groupData.value + exceededAllowance.value;
    return `
        <div class="is-display-flex has-space-between">
          <span>
            Actual data usage
          </span>
          <span class="has-small-margin-left">
            ${humanFileSize(total, 2)}
          </span>
        </div>
      `;
  }

  get monthlyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--group-monthly-usage'
    );
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
