import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["buttonContainer", "form"]

  initialize() {
    this.checkShouldShowForm();
    window.addEventListener('resize', ()  => {
      this.checkShouldShowForm();
    });
  }

  onClick() {
    this.hideButtonContainer();
    this.showForm();
  }

  private

    showForm() {
      this.formTarget.classList.remove("is-hidden");
    }

    hideForm() {
      this.formTarget.classList.add("is-hidden");
    }

    hideButtonContainer() {
      this.buttonContainerTarget.classList.remove('is-display-block@bp-small');
    }

    checkShouldShowForm() {
      if(!this.buttonContainerTargets.length) return;
      if(this.buttonIsVisible()) {
        this.hideForm();
      } else {
        this.showForm();
      }
    }

    buttonIsVisible() {
      let buttonContainerStyle = window.getComputedStyle(this.buttonContainerTarget);
      return buttonContainerStyle.display == 'block';
    }
}
