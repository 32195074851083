import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["assignAllSwitch", "assignSwitch", "labelChecked", "labelUnchecked"]

  initialize() {
    this.updateCheckedStatus()
    this.checkSwitchesVisible()
  }

  onChange(e) {
    let value = e.currentTarget.checked
    this.assignSwitchTargets.forEach((assignSwitch) => {
      assignSwitch.checked = value;
    })
    if (value) {
      this.showCheckedLabel()
    } else {
      this.showUncheckedLabel()
    }
  }

  onClick() {
    this.updateCheckedStatus()
  }

  private

  showCheckedLabel() {
    this.labelUncheckedTarget.classList.add('is-hidden');
    this.labelCheckedTarget.classList.remove('is-hidden');
  }

  showUncheckedLabel() {
    this.labelCheckedTarget.classList.add('is-hidden');
    this.labelUncheckedTarget.classList.remove('is-hidden');
  }

  updateCheckedStatus() {
    let numberOfSwitches = this.assignSwitchTargets.length
    let assignSwitchesChecked = this.assignSwitchTargets.filter(assignSwitch => assignSwitch.checked)
    if (numberOfSwitches > assignSwitchesChecked.length) {
      this.assignAllSwitchTarget.checked = false
      this.showUncheckedLabel();
    } else {
      this.assignAllSwitchTarget.checked = true;
      this.showCheckedLabel();
    }
  }

  checkSwitchesVisible() {
    let numberOfSwitches = this.assignSwitchTargets.length
    if (numberOfSwitches == 0) {
      this.assignAllSwitchTarget.setAttribute('disabled', true)
      this.labelCheckedTarget.classList.add('has-primary-light-text');
    }
  }
}
