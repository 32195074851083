import { Controller } from "stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ["list", "item", "startDate", "dataAmount", "dataUnit"]

  initialize() {
    this.currentUserCreatedAt = this.element.getAttribute('data-current-user-created-at');
    this.id = parseInt(this.element.getAttribute('data-highest-top-up-id')) + 1;
  }

  add(e) {
    e.preventDefault();
    const startDate = moment(this.startDateTarget.value, 'YYYY-MM-DD').format('D/MM/YYYY');
    const dataAllowance = this.dataAmountTarget.value + this.dataUnit.value;
    this.listTarget.insertAdjacentHTML('afterbegin', this.topUpItemTemplate(startDate, dataAllowance, this.id));
    this.id += 1;
    this.clearFields();
  }

private
  clearFields() {
    this.flatpackerController.clear();
    this.dataAmountTarget.value = '';
    this.dataUnitTargets.forEach((unit) => {
      unit.checked = unit.value == "GB" ? true : false;
    })
  }

  topUpItemTemplate(startDate, allowance, id) {
    return `
    <li class="columns has-two has-small-margin-top has-neutral-dark-text" data-controller="devices--update-top-up" data-list-id="${id}" data-target="devices--update-top-up.item">
      <p class="columns-column is-display-flex is-vertically-aligned-center" data-target="devices--update-top-up.startDateText">
        ${startDate}
      </p>
      <div class="columns-column is-display-flex has-space-between is-vertically-aligned-center">
        <p data-target="devices--update-top-up.allowanceText">
          ${allowance}
        </p>
        <ul class="button-list" data-target="devices--update-top-up.editDeleteButtonList">
          <li class="button-list-item is-standout is-neutral-light has-rounded-border has-extra-small-padding-top has-extra-small-padding-bottom has-extra-small-padding-right has-extra-small-margin-right">
            <a href="#" class="button" data-action="devices--update-top-up#edit">
              <i class="button-icon has-extra-small-margin-left has-secondary-svg">
                ${this.pencilSVG}
              </i>
            </a>
          </li>
          <li class="button-list-item is-standout is-neutral-light has-rounded-border has-extra-small-padding-top has-extra-small-padding-bottom has-extra-small-padding-right">
            <a href="#" class="button" data-action="devices--update-top-up#delete">
              <i class="button-icon has-extra-small-margin-left has-secondary-svg">
                ${this.binSVG}
              </i>
            </a>
          </li>
        </ul>
      </div>
      <div class="is-hidden is-full-width" data-target="devices--update-top-up.editForm">
        <div class="columns has-two">
          <div class="columns-column form-field">
            <div data-target="devices--update-top-up.startDateField">
              <div class="form-field-control is-vertically-aligned-center">
                <input type="text_field" id="updated_start_date" name="device[top_ups][${id}][start_date]" class="form-field-input has-input-icon-left" data-controller="forms--datepicker" data-target="devices--update-top-up.startDateFieldInput" data-min-date=${this.currentUserCreatedAt} data-alt-input="true" data-format="YYYY-MM-DD" data-alt-format="d/m/Y" value="${this.startDateTarget.value}">
                <i class="form-field-input-icon has-primary-light-svg">
                  ${this.calendarSVG}
                </i>
              </div>
            </div>
          </div>
          <div class="form-field columns-column columns has-two has-wide-left">
            <div class="columns-column is-right-aligned is-vertically-aligned-center">
              <div class="form-field-control has-neutral-dark-text" data-target="devices--update-top-up.allowanceField" data-field-type="number">
                <input type="number_field" id="updated_amount" name="device[top_ups][${id}][amount]" value="${this.dataAmountTarget.value}" class="form-field-input" data-target="devices--update-top-up.allowanceFieldInput">
              </div>
            </div>
            <div class="form-field-control columns-column is-horizontally-center-aligned" data-field-type="radio-buttons">
              <label for="top_up_${id}_mb" class="form-field-text-switch is-left has-strong-text has-uppercase-text">
                <input type="radio" id="top_up_${id}_mb" value="MB" name="device[top_ups][${id}][unit]" ${this.dataUnit.value === 'MB' ? 'checked' : ''} class="form-field-text-switch" data-target="devices--update-top-up.dataUnitButton">
                <div class="form-field-text-switch-fake">
                  MB
                </div>
              </label>
              <label for="top_up_${id}_gb" class="form-field-text-switch has-strong-text has-uppercase-text">
                <input type="radio" id="top_up_${id}_gb" value="GB" name="device[top_ups][${id}][unit]" ${this.dataUnit.value === 'GB' ? 'checked' : ''} data-target="devices--update-top-up.dataUnitButton">
                <div class="form-field-text-switch-fake">
                  GB
                </div>
              </label>
            </div>
          </div>
        </div>
        <ul class="button-list is-right-aligned has-small-margin-top">
          <li class="button-list-item has-extra-extra-small-margin-right">
            <a href="#", class="button is-chunky is-tertiary has-uppercase-text has-strong-text has-small-min-width has-small-text", data-action="devices--update-top-up#cancel">
              Cancel
            </a>
          </li>
          <li class="button-list-item">
            <a href="#", class="button is-chunky is-quaternary has-uppercase-text has-strong-text has-small-min-width has-small-text", data-action="devices--update-top-up#save">
              Save
            </a>
          </li>
        </ul>
      </div>
    <li>
    `
  }

  get dataUnit() {
    return this.dataUnitTargets.find((unit) => {
      return unit.checked;
    })
  }

  get flatpackerController() {
    const element = this.element.getElementsByTagName('input')[0];
    return this.application.getControllerForElementAndIdentifier(element, 'forms--datepicker');
  }

  get pencilSVG() {
    return `
      <svg width="22" height="22" viewBox="0 0 22 22" aria-hidden="true" class="has-fill">
        <g transform="translate(-844.000000, -87.000000)">
          <g transform="translate(194.000000, 59.999611)">
            <g transform="translate(650.000000, 27.369222)">
              <path d="M21.0958819,4.75475513 L19.1196883,2.77866897 C19.1113041,2.77006982 19.1025974,2.76190062 19.0938908,2.75383891 C19.0858291,2.74513226 19.0775524,2.73642562 19.0690607,2.72793395 L17.0928671,0.751847795 C16.75707,0.416050735 16.2126359,0.416050735 15.8768389,0.751847795 L1.58762058,15.0410661 C1.56021076,15.0684759 1.53581066,15.097713 1.5129154,15.1277026 C1.50764842,15.1345819 1.50259642,15.1414612 1.4976519,15.1485555 C1.47593903,15.1785451 1.45594599,15.2095021 1.43864019,15.2414264 C1.43821023,15.2422864 1.43767278,15.2430388 1.43724283,15.2437912 C1.41886213,15.2781878 1.40359863,15.3136594 1.39016244,15.3497758 C1.38779768,15.3564402 1.3855404,15.3631045 1.38317563,15.3697688 C1.37113681,15.4050254 1.36081782,15.4407119 1.35340105,15.4770433 C1.35307858,15.4782257 1.35264862,15.4793006 1.35243365,15.480483 L1.3518962,15.4830627 C1.35168122,15.4841376 1.35146624,15.485105 1.35125126,15.4862874 L0.364390568,20.4208059 C0.307958604,20.7027507 0.396207447,20.9942621 0.599577497,21.1975247 L0.650205031,21.2481522 C0.813051557,21.4109987 1.03243754,21.5 1.25816539,21.5 C1.31427489,21.5 1.37070685,21.4946255 1.42681635,21.4833391 L6.36165726,20.496371 C6.36262467,20.4962635 6.36348459,20.4960485 6.3643445,20.4958335 L6.36724672,20.4952961 C6.36853659,20.4949736 6.36982646,20.4945436 6.37100885,20.4942212 C6.4071253,20.4869119 6.44259683,20.4765929 6.47763839,20.4645541 C6.48451771,20.4621893 6.49128955,20.459932 6.49816887,20.4574598 C6.53407035,20.4440236 6.56954187,20.4288676 6.60372352,20.4104869 C6.60469092,20.4099494 6.60565833,20.409412 6.60651825,20.4088745 C6.63844261,20.3916762 6.66907711,20.3716832 6.69906667,20.3501853 C6.70616097,20.3451333 6.71314779,20.3399738 6.7201346,20.3347068 C6.75012416,20.3118116 6.7792538,20.2875189 6.80666361,20.2601091 L21.0958819,5.97089083 C21.4317865,5.63509377 21.4317865,5.09055219 21.0958819,4.75475513 Z"></path>
              <path d="M17.7263024,6.90830638 L14.9394233,4.12142726 L16.4849067,2.57594386 L17.8530325,3.94406965 C17.8614167,3.95256132 17.8701233,3.96083801 17.87883,3.96889972 C17.8868917,3.97760636 17.8951684,3.98620552 17.90366,3.99469719 L19.2717858,5.36282298 L17.7263024,6.90830638 Z" fill="#FFFFFF"></path>
              <polygon fill="#FFFFFF" points="2.72378412 17.3932579 4.45447185 19.1239456 2.29103157 19.5565906"></polygon>
              <polygon fill="#FFFFFF" points="6.19859576 18.4360131 3.41182413 15.6491339 13.7232876 5.33745547 16.5102742 8.12433459"></polygon>
            </g>
          </g>
        </g>
      </svg>
    `
  }

  get binSVG() {
    return `
      <svg width="22" height="22" viewBox="0 0 16 22" aria-hidden="true" class="has-fill">
        <g transform="translate(-847.000000, -144.000000)">
          <g transform="translate(194.000000, 59.999611)">
            <g transform="translate(653.000000, 83.687500)">
              <path d="M14.5772217,5.58116067 L1.43994482,5.58116067 C0.94212893,5.58116067 0.538640609,5.17767235 0.538640609,4.67985646 L0.538640609,2.53552697 C0.538640609,2.0756801 0.884796809,1.68960776 1.34193934,1.63963146 L3.70661889,1.38077152 L5.85062386,0.614576406 C5.94798029,0.579852612 6.05052906,0.562112107 6.15394323,0.562112107 L9.80069881,0.562112107 C9.90422114,0.562112107 10.0067699,0.579852612 10.1040182,0.614576406 L12.2469414,1.38033883 L14.6728473,1.63930693 C15.1309634,1.6882015 15.4784177,2.0748147 15.4784177,2.53552697 L15.4784177,4.67985646 C15.4784177,5.17767235 15.0749294,5.58116067 14.5772217,5.58116067 Z"></path>
              <path d="M2.34124902,3.77855226 L13.6759175,3.77855226 L13.6759175,3.34574883 L11.9489226,3.16131248 C11.8780687,3.15374031 11.8084048,3.13783876 11.741337,3.11382418 L9.64460399,2.36461235 L6.31003804,2.36461235 L4.21330503,3.11382418 C4.14699448,3.13751424 4.07808776,3.15341579 4.00809931,3.16109613 L2.34124902,3.34358535 L2.34124902,3.77855226 Z" fill="#FFFFFF"></path>
              <path d="M12.268468,21.8321126 L3.60763985,21.8321126 C3.60223116,21.8321126 3.59693064,21.8321126 3.59152195,21.8320044 C2.06973275,21.8048528 0.829303643,20.6963957 0.599758813,19.2359411 C0.520683756,19.0984521 0.478495969,18.9412756 0.479794054,18.7799884 L0.562006152,7.32578792 C0.565575888,6.83067638 0.968090645,6.43108232 1.46331036,6.43108232 L14.496524,6.43108232 C14.7367781,6.43108232 14.9669719,6.52692432 15.1361558,6.69729807 C15.3053396,6.86788817 15.399559,7.09873109 15.3978282,7.33898513 L15.3141017,18.7930775 C15.3141017,18.7962145 15.3139935,18.7993515 15.3139935,18.8024886 C15.2842457,20.4715023 13.9535997,21.8022566 12.2845859,21.8320044 C12.2791772,21.8321126 12.2738767,21.8321126 12.268468,21.8321126 Z"></path>
              <path d="M3.61672645,20.0295041 L12.2593814,20.0295041 C12.9485568,20.0136026 13.4971062,19.4640796 13.5116015,18.7745797 L13.5887294,8.23358256 L2.35812414,8.23358256 L2.28489047,18.415659 C2.33400138,18.5240492 2.36212657,18.644014 2.36439822,18.7704691 C2.37673004,19.4619162 2.92614483,20.0136026 3.61672645,20.0295041 Z" fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </svg>
    `
  }

  get calendarSVG() {
    return `
      <svg width="20" height="20" viewBox="0 0 24 24" aria-hidden="true" class="has-fill">
        <g transform="translate(-1070.000000, -424.000000)">
          <g transform="translate(1070.985352, 424.414062)">
            <path d="M3.01677817,3.82009861 C2.3107545,3.82009861 1.73635058,4.39450253 1.73635058,5.1005262 L1.73635058,19.9037556 C1.73635058,20.6097793 2.3107545,21.1841832 3.01677817,21.1841832 L19.906939,21.1841832 C20.6129627,21.1841832 21.1873666,20.6097793 21.1873666,19.9037556 L21.1873666,5.1005262 C21.1873666,4.39450253 20.6129627,3.82009861 19.906939,3.82009861 L3.01677817,3.82009861 Z M19.906939,22.9205916 L3.01677817,22.9205916 C1.35329887,22.9205916 -5.78802819e-05,21.5673507 -5.78802819e-05,19.9037556 L-5.78802819e-05,5.1005262 C-5.78802819e-05,3.4370469 1.35329887,2.08369015 3.01677817,2.08369015 L19.906939,2.08369015 C21.5704183,2.08369015 22.9237751,3.4370469 22.9237751,5.1005262 L22.9237751,19.9037556 C22.9237751,21.5673507 21.5704183,22.9205916 19.906939,22.9205916 Z"></path>
            <path d="M5.67542789,5.88236148 C5.19583188,5.88236148 4.80722366,5.4936375 4.80722366,5.01415725 L4.80722366,0.868192653 C4.80722366,0.388596637 5.19583188,-1.15760564e-05 5.67542789,-1.15760564e-05 C6.15502391,-1.15760564e-05 6.54363212,0.388596637 6.54363212,0.868192653 L6.54363212,5.01415725 C6.54363212,5.4936375 6.15502391,5.88236148 5.67542789,5.88236148"></path>
            <path d="M11.3932589,5.88236148 C10.9136629,5.88236148 10.5250547,5.4936375 10.5250547,5.01415725 L10.5250547,0.868192653 C10.5250547,0.388596637 10.9136629,-1.15760564e-05 11.3932589,-1.15760564e-05 C11.8728549,-1.15760564e-05 12.2614631,0.388596637 12.2614631,0.868192653 L12.2614631,5.01415725 C12.2614631,5.4936375 11.8728549,5.88236148 11.3932589,5.88236148"></path>
            <path d="M17.0778434,5.88236148 C16.5982474,5.88236148 16.2096392,5.4936375 16.2096392,5.01415725 L16.2096392,0.868192653 C16.2096392,0.388596637 16.5982474,-1.15760564e-05 17.0778434,-1.15760564e-05 C17.5574395,-1.15760564e-05 17.9460477,0.388596637 17.9460477,0.868192653 L17.9460477,5.01415725 C17.9460477,5.4936375 17.5574395,5.88236148 17.0778434,5.88236148"></path>
            <path d="M7.93275889,9.63706694 C7.93275889,10.3882372 7.32374256,10.9972536 6.57257226,10.9972536 C5.82140196,10.9972536 5.21238564,10.3882372 5.21238564,9.63706694 C5.21238564,8.88578088 5.82140196,8.27688032 6.57257226,8.27688032 C7.32374256,8.27688032 7.93275889,8.88578088 7.93275889,9.63706694"></path>
            <path d="M12.8318964,9.63706694 C12.8318964,10.3882372 12.2228801,10.9972536 11.4717098,10.9972536 C10.7205395,10.9972536 10.1115232,10.3882372 10.1115232,9.63706694 C10.1115232,8.88578088 10.7205395,8.27688032 11.4717098,8.27688032 C12.2228801,8.27688032 12.8318964,8.88578088 12.8318964,9.63706694"></path>
            <path d="M17.7311498,9.63706694 C17.7311498,10.3882372 17.1221334,10.9972536 16.3709631,10.9972536 C15.6197928,10.9972536 15.0107765,10.3882372 15.0107765,9.63706694 C15.0107765,8.88578088 15.6197928,8.27688032 16.3709631,8.27688032 C17.1221334,8.27688032 17.7311498,8.88578088 17.7311498,9.63706694"></path>
            <path d="M7.93275889,13.341405 C7.93275889,14.0925753 7.32374256,14.7015916 6.57257226,14.7015916 C5.82140196,14.7015916 5.21238564,14.0925753 5.21238564,13.341405 C5.21238564,12.5901189 5.82140196,11.9812184 6.57257226,11.9812184 C7.32374256,11.9812184 7.93275889,12.5901189 7.93275889,13.341405"></path>
            <path d="M12.8318964,13.341405 C12.8318964,14.0925753 12.2228801,14.7015916 11.4717098,14.7015916 C10.7205395,14.7015916 10.1115232,14.0925753 10.1115232,13.341405 C10.1115232,12.5901189 10.7205395,11.9812184 11.4717098,11.9812184 C12.2228801,11.9812184 12.8318964,12.5901189 12.8318964,13.341405"></path>
            <path d="M17.7311498,13.341405 C17.7311498,14.0925753 17.1221334,14.7015916 16.3709631,14.7015916 C15.6197928,14.7015916 15.0107765,14.0925753 15.0107765,13.341405 C15.0107765,12.5901189 15.6197928,11.9812184 16.3709631,11.9812184 C17.1221334,11.9812184 17.7311498,12.5901189 17.7311498,13.341405"></path>
            <path d="M7.93275889,17.045743 C7.93275889,17.7969133 7.32374256,18.4059297 6.57257226,18.4059297 C5.82140196,18.4059297 5.21238564,17.7969133 5.21238564,17.045743 C5.21238564,16.294457 5.82140196,15.6855564 6.57257226,15.6855564 C7.32374256,15.6855564 7.93275889,16.294457 7.93275889,17.045743"></path>
            <path d="M12.8318964,17.045743 C12.8318964,17.7969133 12.2228801,18.4059297 11.4717098,18.4059297 C10.7205395,18.4059297 10.1115232,17.7969133 10.1115232,17.045743 C10.1115232,16.294457 10.7205395,15.6855564 11.4717098,15.6855564 C12.2228801,15.6855564 12.8318964,16.294457 12.8318964,17.045743"></path>
            <path d="M17.7311498,17.045743 C17.7311498,17.7969133 17.1221334,18.4059297 16.3709631,18.4059297 C15.6197928,18.4059297 15.0107765,17.7969133 15.0107765,17.045743 C15.0107765,16.294457 15.6197928,15.6855564 16.3709631,15.6855564 C17.1221334,15.6855564 17.7311498,16.294457 17.7311498,17.045743"></path>
          </g>
        </g>
      </svg>
    `
  }
}
