import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'alarmFilter',
    'tagFilter',
    'orderFilter',
    'device',
    'devicesField',
    'list',
    'assignAllSwitch'
  ];

  initialize() {
    this.alarmId = this.element.getAttribute('data-alarm-id');
    this.allDeviceIds = JSON.parse(
      this.assignAllSwitchTarget.getAttribute('data-all-devices')
    );
    this.selectedDeviceIds =
      JSON.parse(this.element.getAttribute('data-selected-devices')) || [];
    this.setupCheckboxes();
  }

  onChange(e) {
    let data = this.setData();
    this.filter(data, e);
  }

  onDeviceSelect(e) {
    let selectedId = parseInt(e.target.value);
    if (e.target.checked) {
      this.selectedDeviceIds.push(selectedId);
    } else {
      this.selectedDeviceIds = this.selectedDeviceIds.filter(
        (id) => id !== selectedId
      );
    }
    this.devicesFieldTarget.value = this.selectedDeviceIds.map((id) =>
      id.toString()
    );
  }

  onDeviceSelectAll(e) {
    let selectedIds = this.allDeviceIds;
    if (e.target.checked) {
      selectedIds.forEach((selectedId) =>
        this.selectedDeviceIds.push(selectedId)
      );
    } else {
      selectedIds.forEach(
        (selectedId) =>
          (this.selectedDeviceIds = this.selectedDeviceIds.filter(
            (id) => id !== selectedId
          ))
      );
    }
    this.devicesFieldTarget.value = this.selectedDeviceIds.map((id) =>
      id.toString()
    );
  }

  onAjaxSuccess(e) {
    let [_data, _status, xhr] = e.detail;
    this.updateDevicesList(xhr.response);
    e.stopPropagation();
  }

  private;

  filter(data, e) {
    let url = '/alarms/filter_devices';
    let fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.updateDevicesList(data);
      });
  }

  updateDevicesList(data) {
    this.listTarget.innerHTML = data;
    this.allDeviceIds = JSON.parse(
      this.assignAllSwitchTarget.getAttribute('data-all-devices')
    );
    this.devicesFieldTarget.value = this.selectedDeviceIds.map((id) =>
      id.toString()
    );
    this.setupSelectAllCheckbox();
    this.setupCheckboxes();
  }

  setData() {
    return {
      id: this.alarmId,
      alarm_id: this.alarmFilterTarget.value,
      tag_id: this.tagFilterTarget.value,
      order: this.orderFilterTarget.value
    };
  }

  setupCheckboxes() {
    this.deviceTargets.find((target) => {
      target.checked = this.selectedDeviceIds.includes(parseInt(target.value));
    });
  }

  setupSelectAllCheckbox() {
    this.assignAllSwitchTarget.checked =
      this.selectedDeviceIds.length === this.allDeviceIds.length;
  }
}
