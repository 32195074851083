import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import moment from 'moment';
import {
  utcDateFormat,
  renderAppUsageList,
  showLoading,
  hideLoading
} from './helpers';

export default class extends Controller {
  static targets = ['monthSelect', 'appUsageList', 'totalText', 'loadingIcon'];

  initialize() {
    this.fetchAppUsageData();
  }

  onChangeMonthSelect() {
    this.fetchAppUsageData();
  }

  private;

  buildFetchDataURL() {
    return `/dashboard/app_usage.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
  }

  fetchAppUsageData() {
    showLoading(this.loadingIconTarget, this.appUsageListTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = this.buildFetchDataURL();
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        hideLoading(this.loadingIconTarget, this.appUsageListTarget);
        renderAppUsageList(
          this.appUsageListTarget,
          this.totalTextTarget,
          data.app_usage
        );
      });
  }

  get monthlyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--group-monthly-usage'
    );
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
