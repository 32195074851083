import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectAllDevicesCheckbox", "deviceCheckbox", "singularText", "pluralText"]

  onChange(e) {
    let value = e.currentTarget.checked
    this.deviceCheckboxTargets.forEach((deviceCheckbox) => {
      deviceCheckbox.checked = value;
    })
  }

  onClick() {
    let numberOfCheckboxes = this.deviceCheckboxTargets.length
    let checkboxesChecked = this.deviceCheckboxTargets.filter(deviceCheckbox => deviceCheckbox.checked)
    if (numberOfCheckboxes > checkboxesChecked.length) {
      this.selectAllDevicesCheckboxTarget.checked = false
    } else {
      this.selectAllDevicesCheckboxTarget.checked = true;
    }
  }

  updateSelectedCount() {
    let checkboxesCheckedCount = this.deviceCheckboxTargets
      .filter(deviceCheckbox => deviceCheckbox.checked)
      .length

      checkboxesCheckedCount == 1 
        ? this.showHide(this.singularTextTargets, this.pluralTextTargets)
        : this.showHide(this.pluralTextTargets, this.singularTextTargets);
  }

  private

  showHide(show, hide) {
    show.forEach( el => el.classList.remove('is-hidden'))
    hide.forEach( el => el.classList.add('is-hidden'))
  }
}