import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import nv from 'nvd3';
import 'nvd3/build/nv.d3.min.css';
import moment from 'moment';
import {
  utcDateFormat,
  humanFileSize,
  renderAppUsageList,
  showLoading,
  hideLoading
} from './helpers';
import { dispatchEvent, removeElement } from '../../helpers';

export default class extends Controller {
  static targets = [
    'totalUsageText',
    'monthSelect',
    'monthSelectContainer',
    'chartContainer',
    'chart',
    'appUsageList',
    'totalText',
    'legend',
    'loadingIcon'
  ];

  initialize() {
    this.deviceId = this.element.getAttribute('data-device-id');
    this.deviceHome = this.element.getAttribute('data-device-home');
    this.appView = this.element.getAttribute('data-app-view');
    this.fetchMonthlyData();
  }

  show() {
    this.chartContainerTarget.classList.remove('is-hidden');
    this.monthSelectContainerTarget.classList.remove('is-hidden');
    dispatchEvent(window, 'resize');
  }

  hide() {
    this.chartContainerTarget.classList.add('is-hidden');
    this.monthSelectContainerTarget.classList.add('is-hidden');
  }

  onChangeMonthSelect() {
    this.fetchMonthlyData();
  }

  private;

  buildFetchDataURL() {
    let date = moment.utc(this.monthSelectTarget.value).format(utcDateFormat);
    if (this.appView == 'yes') {
      return `/mobile/monthly_usage.json?month=${encodeURIComponent(date)}`;
    }
    return `/devices/${this.deviceId}.json?month=${encodeURIComponent(date)}`;
  }

  fetchMonthlyData() {
    showLoading(this.loadingIconTarget, this.chartTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = this.buildFetchDataURL();
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        let totalUsageText = data.total_summary;
        this.totalUsageTextTarget.innerText = totalUsageText;
        delete data.total_summary;
        this.generateChart(data);
      });
  }

  generateChart(data) {
    this.removeLegend();
    this.removeLabels();
    nv.addGraph({
      generate: () => {
        let chart = nv.models
          .multiBarChart()
          .stacked(true)
          .showControls(false)
          .showLegend(false)
          .margin({ top: 20, right: 0, bottom: 50, left: 65 })
          .duration(0)
          .height(300)
          .reduceXTicks(false);
        chart.dispatch.on('renderEnd', () => {
          this.resetVisibility();
          this.addZeroLabels();
          this.applyZeroClass();
        });
        chart.yAxis.tickFormat((d) => {
          return humanFileSize(d, 2);
        });
        chart.yAxis.tickPadding(12);
        chart.xAxis.tickPadding(12);

        let monthText = this.getMonthText();
        chart.xAxis.axisLabel(monthText);
        let chartData = this.generateData(data);
        this.generateLegend(chartData);
        this.generateToolTip(chart);
        let svg = d3.select(this.chartTarget).datum(chartData);
        svg
          .transition()
          .duration(0)
          .call(chart);
        return chart;
      },
      callback: (graph) => {
        nv.utils.windowResize(() => {
          d3.select(this.chartTarget)
            .transition()
            .duration(0)
            .call(graph);
          this.removeLabels();
          this.applyZeroClass();
          this.addZeroLabels();
        });
        this.applyZeroClass();

        graph.multibar.dispatch.on('elementClick', (e) => {
          this.showDailyUsage(e);
        });
        hideLoading(this.loadingIconTarget, this.chartTarget);

        d3.selectAll('rect.nv-bar').on('touchend', (e) => {
          this.showDailyUsage({ data: e });
        });
      }
    });
  }

  generateData(data) {
    let homeColor = '#73CFDE';
    let zeroColour = '#F3F4F7';
    let emptyColour = '#EBECF2';
    let clickableColour = '#FFFFFF';
    let colors = [
      '#07AA17',
      '#FF9100',
      '#F50057',
      '#60D9EA',
      '#8686B2',
      '#B2B4CA',
      '#83D48B',
      '#FFC77E',
      '#FF91B8',
      '#A8F4FF',
      '#C5C5F1'
    ];
    let colourIndexOffset = Object.keys(data).includes(this.deviceHome) ? 1 : 0;
    return Object.keys(data).map((country, index) => {
      if (country == this.deviceHome) {
        var color = homeColor;
      } else if (country == 'Empty') {
        var color = emptyColour;
      } else if (country == 'Zero') {
        var color = zeroColour;
      } else if (country == 'Clickable') {
        var color = clickableColour;
      } else {
        let adjustedIndex = index - colourIndexOffset;
        let colorIndex =
          adjustedIndex > colors.length - 1
            ? adjustedIndex - colors.length
            : adjustedIndex;
        var color = colors[colorIndex];
      }
      return {
        key: country,
        values: data[country],
        color: color
      };
    });
  }

  generateLegend(data) {
    let homeDataItem = data.find((dataItem) => dataItem.key == this.deviceHome);
    let excludedKeys = ['Home', 'Zero', 'Empty', 'Clickable'];
    let legendData = [];
    if (homeDataItem) {
      homeDataItem.key = 'Home';
      legendData.push(homeDataItem);
    }
    let filteredLegendData = data.reduce((filtered, dataItem) => {
      if (!excludedKeys.includes(dataItem.key)) filtered.push(dataItem);
      return filtered;
    }, legendData);
    this.renderLegendTemplate(filteredLegendData);
  }

  renderLegendTemplate(data) {
    const template = `
        <ul class="is-display-flex" data-target="charts--monthly-usage.legend">
          ${this.renderLegendItems(data)}
        </ul>
      `;
    this.chartContainerTarget.insertAdjacentHTML('afterbegin', template);
  }

  renderLegendItems(data) {
    return data
      .map((dataItem) => {
        return `
          <li class="is-display-flex has-tiny-text has-uppercase-text has-strong-text has-small-margin-right is-vertically-aligned-center">
            <i>
              <svg width="30" height="8" viewBox="0 0 30 9" aria-hidden="true" class="has-fill">
                <rect width="30" height="8" fill="${dataItem.color}"></rect>
              </svg>
            </i>
            <span class="has-tiny-margin-left">
              ${dataItem.key}
            </span>
          </li>
        `;
      })
      .join('');
  }

  removeLegend() {
    if (this.hasLegendTarget) {
      removeElement(this.legendTarget);
    }
  }

  generateToolTip(chart) {
    chart.tooltip.contentGenerator((obj) => {
      let data_used = humanFileSize(obj.data.size, 2);
      return `
          <div class="has-tiny-text">
            <div class="is-display-flex has-space-between has-tiny-margin-bottom">
              <span class="has-strong-text has-uppercase-text">
                ${obj.data.key}
              </span>
              <span class="has-strong-text has-small-margin-left">
                ${obj.data.day}
              </span>
            </div>
            <div class="is-display-flex has-space-between has-tiny-margin-bottom">
              <span>
                Data usage
              </span>
              <span class="has-small-margin-left">
                ${data_used}
              </span>
            </div>
            <span class="is-display-block has-center-aligned-text has-small-text">
              Click for day view
            </span>
          </div>
        `;
    });
  }

  applyZeroClass() {
    d3.selectAll('.nv-multibar .nv-group').each(function(group) {
      if (
        group.key != 'Zero' &&
        group.key != 'Empty' &&
        group.key != 'Clickable'
      ) {
        return false;
      }
      let g = d3.select(this);
      g.selectAll('.nv-bar').each(function() {
        let b = d3.select(this);
        if (group.key != 'Clickable') {
          b.classed('zero', true);
        }
        b.on('mouseover', () => {});
      });
    });
  }

  addZeroLabels() {
    d3.selectAll('.nv-multibar .nv-group').each(function(group) {
      if (group.key != 'Zero' && group.key != 'Empty') {
        return false;
      }
      let label = 'ZERO DATA USED';
      if (group.key == 'Empty') {
        label = 'NO RECORD OF USAGE';
      }
      let g = d3.select(this);
      g.selectAll('text').remove();
      g.selectAll('.nv-bar').each(function(bar) {
        if (bar.y > 0) {
          let b = d3.select(this);
          b.attr('visibility', 'visible');
          if (new Date(bar.day) > new Date()) {
            b.attr('visibility', 'hidden');
          } else {
            b.on('click', () => {});
            let barWidth = b.attr('width');
            let barHeight = b.attr('height');
            let x =
              parseFloat(b.attr('transform').split('(')[1]) + barWidth / 2 + 4;
            let y = barHeight / 2;
            g.append('text')
              .attr('transform', b.attr('transform'))
              .text(() => {
                return label;
              })
              .attr('transform', 'translate(' + x + ',' + y + ') rotate(-90)')
              .attr('text-anchor', 'middle')
              .attr('class', 'zero-label');
          }
        }
      });
    });
  }

  resetVisibility() {
    d3.selectAll('.nv-multibar .nv-group .nv-bar').each(function() {
      let b = d3.select(this);
      b.attr('visibility', 'visible');
    });
  }

  removeLabels() {
    d3.selectAll('.nv-multibar .nv-group text').remove();
  }

  getMonthText() {
    return this.monthSelectTarget.options[this.monthSelectTarget.selectedIndex]
      .label;
  }

  showDailyUsage(e) {
    let date = moment(e.data.day).format();
    this.hide();
    this.dailyUsageController.setDaySelectValue(date);
    this.dailyUsageController.show();
    this.dailyUsageController.fetchDailyData();
  }

  renderAppUsageList(data) {
    renderAppUsageList(this.appUsageListTarget, this.totalTextTarget, data);
  }

  get dailyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--daily-usage'
    );
  }
}
