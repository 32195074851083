import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "submitButton"]

  connect() {
    this.validate();
  }

  validate() {
    let valid = true;
    this.fieldTargets.forEach((field) => {
      if (!this.validateField(field)) {
        valid = false;
      }
    });
    if (valid) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  validateOnClick() {
    this.submitButtonTarget.removeAttribute('disabled');
  }

  onSubmit() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  private

    validateField(field) {
      let fieldType = field.getAttribute('data-field-type');
      if (field.classList.contains('is-hidden')) return true;

      switch (fieldType) {
        case "radio-buttons":
          let checkedRadioButtons = field.querySelectorAll('input[type="radio"]:checked');
          if (checkedRadioButtons.length === 0) {
            return false;
          }
          break;
        case "checkboxes":
          let checkedCheckboxes = field.querySelectorAll('input[type="checkbox"]:checked');
          if (!checkedCheckboxes.length) {
            return false;
          }
          break;
        case "number":
          let numberInput = field.querySelector('input[type="number"]');
          if (!numberInput.value || numberInput.value < numberInput.min ) {
            return false;
          }
          break;
        case "button":
          return false;
        case "multi-select":
          let selectedSelects = field.querySelectorAll('.has-value-selected');
          if (!selectedSelects.length) {
            return false;
          }
          break;
        default:
          let input = field.querySelector('input') || field.querySelector('select');
          if (!input.value) {
            return false;
          }
      }
      return true;
  }
}
