import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "cardDetails", "errorContainer", "cardFormField", "submitButton", "nameField"]

  initialize() {
    this.stripe = Stripe('pk_test_I5ZgAf6MXuiDi0b1xSyFBjth');
    this.elements = this.stripe.elements();
    this.setupCard();
    this.card.mount(this.cardDetailsTarget);
    this.cardDetailsComplete = false
    this.nameFieldComplete = false
  }

  setupCard() {
    let style = {
      base: {
        color: '#1A1E40',
        fontFamily: '"WorkSans", Helvetica, Arial, "Lucida Grande", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#A4A6B6'
        }
      },
      invalid: {
        color: '#FF9100',
        iconColor: '#FF9100'
      }
    };
    this.card = this.elements.create('card', {iconStyle: 'solid', style: style});

    this.card.addEventListener('change', (event) => {
      this.cardDetailsComplete = false
      if(event.complete) {
        this.cardDetailsComplete = true
      }
      if (event.error) {
        this.showError(error.message);
      } else {
        this.hideError();
      }
      this.validate()
    });
  }


  onSubmit(e) {
    e.preventDefault()
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.showError(result.error.message);
      } else {
        let form = this.formTarget;
        let hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripe_token');
        hiddenInput.setAttribute('value', result.token.id);
        form.appendChild(hiddenInput);
        form.submit();
      }
    });
  }

  validate() {
    let valid = true;
    valid = this.cardDetailsComplete && this.nameFieldTarget.value
    if(valid) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  private

    showError(message) {
      this.errorContainerTarget.textContent = message
      this.cardFormFieldTarget.classList.add("has-error")
    }

    hideError() {
      this.cardFormFieldTarget.classList.remove("has-error")
    }
}
