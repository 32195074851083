import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  onAnswer(e) {
    let answer = e.currentTarget.getAttribute('data-answer');
    this.contentTargets.forEach(content => {
      let showWhenAnswer = content.getAttribute('data-show-when-answer');
      if(showWhenAnswer == answer || showWhenAnswer == "any") {
        this.show(content);
      }
      else {
        this.hide(content);
      }
    });
  }

  private

    show(content) {
      content.classList.remove('is-hidden');
    }

    hide(content) {
      content.classList.add('is-hidden');
    }
}
