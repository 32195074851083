import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
export default class extends Controller {
  static targets = [
    "overlay"
  ];

  initialize() {
  }

  hideOverlay(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.overlayTarget.classList.add("is-hidden");
  }

  showOverlay() {
    this.overlayTarget.classList.remove("is-hidden");
  }
}
