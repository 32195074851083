import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import { dispatchEvent } from "../../helpers"

export default class extends Controller { 
  static targets = ["form", "tabs", "tabLink"]

  onChangeSwitch(e) {
    e.preventDefault();
    let form = this.formTargets.find((formTarget) => {
      return formTarget.getAttribute("data-alarm-id") == e.currentTarget.value;
    });
    if(form) Rails.fire(form, 'submit');
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.element.innerHTML = xhr.response;
    let activeTabLink = this.findActiveTab();
    if(activeTabLink) dispatchEvent(activeTabLink, 'click');
    this.updateTotalAlarmCount();
  }

  private

    findActiveTab() {
      let locationHash = window.location.hash
      return this.tabLinkTargets.find((tabLinkTarget) => {
        return locationHash == tabLinkTarget.getAttribute('href');
      });
    }

    updateTotalAlarmCount() {
      let totalAlarmCount = this.tabsTarget.getAttribute("data-total-alarm-count");
      this.totalAlarmCountController.update(totalAlarmCount);
    }

    get totalAlarmCountController() {
      let alarmCountElement = document.querySelector('[data-text-update-id="device-total-alarm-count"]');
      return this.application.getControllerForElementAndIdentifier(alarmCountElement, "ui--text-update");
    }
}
