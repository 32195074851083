import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["individualAmount", "individualUnit"]

  initialize() {
    this.topUpAmount = parseFloat(this.element.getAttribute('data-top-up-amount')) || 0;
  }

  onAnswer() {
    this.calculateTotal();
  }

private
  calculateTotal() {
    const individualUnit = this.individualUnitTargets.find(unit => unit.checked);
    let individualInitialValue = this.individualAmountTarget.value || 0;
    const individualValue = this.calculateAmountInBytes(individualInitialValue, individualUnit.attributes.value);
    this.totalAmount = this.humanReadableAmount(individualValue);
  }

  totalTextTemplate(totalAmount) {
    return `Total monthly domestic allowance: ${totalAmount}`;
  }

  calculateAmountInBytes(amount, unit) {
    if (unit.value === 'GB') {
      return (parseFloat(amount) * 1000000000);
    } else {
      return parseFloat(amount) * 1000000;
    }
  }

  humanReadableAmount(bytes) {
    const thresh = 1000;
    if (bytes < thresh) return bytes + ' B';
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index = -1;
    do {
      bytes /= thresh;
      index++;
    } while (bytes >= thresh);
    return bytes + units[index];
  }
}
