import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    this.path = this.element.getAttribute('data-path');
  }
  connect() {
    this.retrieve();
  }

  retrieve(body = '') {
    let fetchData = {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    fetch(this.path, fetchData)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.updateMenu(data);
      });
  }

  onAjaxSuccess(e) {
    let [data, status, xhr] = e.detail;
    this.updateMenu(xhr.response);
  }

  updateMenu(data) {
    let form = this.formTarget;
    form.innerHTML = data;
  }
}
