import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  onAnswer(e) {
    let value = e.currentTarget.getAttribute('value');
    let answerGroup = e.currentTarget.getAttribute('data-answer-group');
    const elements = this.contentTargets.filter(content => {
      let showWhenAnswer = content.getAttribute('data-answer-group');
      return showWhenAnswer === answerGroup;
    });

    elements.forEach((element) => {
      if (element.getAttribute('data-show-when-value') === value) {
        this.show(element);
      } else {
        this.hide(element);
      }
    });
  }

  private

    show(content) {
      content.classList.remove('is-hidden');
    }

    hide(content) {
      content.classList.add('is-hidden');
    }
}
