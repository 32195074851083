import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';
import {
  utcDateFormat,
  showLoading,
  hideLoading,
  humanFileSize
} from './helpers';

export default class extends Controller {
  static targets = [
    'listContainer',
    'list',
    'loadingIcon',
    'monthSelect',
    'title'
  ];

  initialize() {
    this.fetchData();
  }

  onChangeMonthSelect() {
    if (this.hasListTarget) {
      this.listContainerTarget.removeChild(this.listTarget);
    }
    this.fetchData();
  }

  private;

  fetchData() {
    showLoading(this.loadingIconTarget, this.listContainerTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = `dashboard/roaming_users.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.setTitle(data);
        this.generateList(data);
        hideLoading(this.loadingIconTarget, this.listContainerTarget);
      });
  }

  setTitle(data) {
    this.titleTarget.innerHTML = '';
    if (Object.values(data).length == 10) {
      this.titleTarget.insertAdjacentHTML('afterbegin', 10);
    }
  }

  generateList(data) {
    const list = `
      <ul class="decimal-list" data-target="charts--top-roaming-users.list">
        ${this.renderListTemplate(data)}
      </ul>`;
    this.listContainerTarget.insertAdjacentHTML('afterbegin', list);
  }

  renderListTemplate(data) {
    return Object.values(data)
      .map((entry) => {
        return `
        <li class="decimal-list-item has-primary-number has-pale-number-background has-extra-extra-small-margin-bottom">
          <div class="is-display-flex has-space-between is-flexible is-vertically-aligned-center is-flex-wrap">
            <div class="is-display-flex is-column-direction has-extra-small-margin-right">
              <span class="has-pale-text has-capitalized-text has-small-text">
                ${entry.first_name} ${entry.last_name}
              </span>
              <a href="/devices/${entry.id}">
                <span class="is-default-text has-medium-strong-text has-secondary-text">
                  ${entry.phone_number}
                </span>
              </a>
            </div>
            <span class="has-pale-text has-strong-text has-small-text">
              ${humanFileSize(entry.data_used, 2)}
            </span>
          </div>
        </li>`;
      })
      .join('');
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
