import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['list', 'statusFilter', 'tagFilter', 'queryFilter'];

  onChange(e) {
    let data = this.setData();
    this.filter(data, e);
  }

  private;

  filter(data, e) {
    let url = '/devices_management/filter';
    let fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };

    fetch(url, fetchData)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.updateDevicesList(data);
      });
  }

  updateDevicesList(data) {
    let filteredList = this.listTarget;
    filteredList.innerHTML = data;
  }

  setData() {
    return {
      search: {
        status: this.statusFilterTarget.value,
        tag: this.tagFilterTarget.value,
        query: this.queryFilterTarget.value
      }
    };
  }
}
