import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import nv from 'nvd3';
import 'nvd3/build/nv.d3.min.css';
import moment from 'moment';
import 'moment-timezone';
import {
  utcDateFormat,
  dataValues,
  humanFileSize,
  renderAppUsageList,
  showLoading,
  hideLoading
} from './helpers';
import { dispatchEvent } from '../../helpers';

export default class extends Controller {
  static targets = [
    'datepicker',
    'daySelectContainer',
    'chartContainer',
    'chart',
    'appUsageList',
    'totalText',
    'loadingIcon'
  ];

  initialize() {
    this.deviceId = this.element.getAttribute('data-device-id');
    this.appView = this.element.getAttribute('data-app-view');
  }

  show() {
    this.chartContainerTarget.classList.remove('is-hidden');
    this.daySelectContainerTarget.classList.remove('is-hidden');
    dispatchEvent(window, 'resize');
  }

  hide() {
    this.chartContainerTarget.classList.add('is-hidden');
    this.daySelectContainerTarget.classList.add('is-hidden');
  }

  onChangeDaySelect() {
    this.fetchDailyData();
  }

  onClickNextDay(e) {
    e.preventDefault();
    let nextDate = this.currentDate.add(1, 'day');
    let maxDate = this.datepickerController.maxDate;
    if (nextDate.isBefore(maxDate.add(1, 'day'))) {
      this.datepickerController.fp.setDate(nextDate.format(), true);
    }
  }

  onClickPreviousDay(e) {
    e.preventDefault();
    let previousDate = this.currentDate.subtract(1, 'day');
    let minDate = this.datepickerController.minDate;
    if (previousDate.isAfter(minDate.subtract(1, 'day'))) {
      this.datepickerController.fp.setDate(previousDate.format(), true);
    }
  }

  onClickViewMonthButton(e) {
    e.preventDefault();
    this.hide();
    this.showTooltip();
    this.monthlyUsageController.show();
  }

  setDaySelectValue(date) {
    this.datepickerController.fp.setDate(date);
  }

  fetchDailyData() {
    showLoading(this.loadingIconTarget, this.chartTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = this.buildFetchDataURL();
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.deleteChart();
        this.generateChart(data);
        if (data.is_android) {
          renderAppUsageList(
            this.appUsageListTarget,
            this.totalTextTarget,
            data.totals
          );
        }
      });
  }

  private;

  buildFetchDataURL() {
    let date = this.currentDate.format(utcDateFormat);
    if (this.appView == 'yes') {
      return `/mobile/app_usage.json?date=${encodeURIComponent(date)}`;
    }
    return `/devices/${this.deviceId}/app_usage.json?date=${encodeURIComponent(
      date
    )}`;
  }

  generateChart(data) {
    var dateSplit = this.datepickerTarget.value.split('-').map(function(i) {
      return parseInt(i);
    });
    var minDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    var maxDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    maxDate.setSeconds(59);

    var maxY = d3.max(data.points, function(d) {
      return d.total;
    });
    if (maxY === 0) {
      maxY = 1;
    }
    maxY = maxY + maxY * 0.1;
    nv.addGraph({
      generate: () => {
        var chart = nv.models
          .lineChart()
          .noData('No data available')
          .margin({ top: 20, right: 20, bottom: 50, left: 65 })
          .useInteractiveGuideline(true)
          .showLegend(false)
          .showYAxis(true)
          .showXAxis(true)
          .height(300)
          .duration(100)
          .forceX([minDate, maxDate])
          .forceY([0, maxY])
          .pointSize(50)
          .xScale(d3.time.scale());

        this.generateToolTip(chart, data.points, data.timezone);

        if (data.points.length) {
          this.showTooltip();
        } else {
          this.hideTooltip();
        }

        chart.xAxis
          .axisLabel('TIME OF DAY')
          .domain([minDate, maxDate])
          .ticks(14)
          .showMaxMin(true)
          .tickFormat(function(d) {
            var date = new Date(d);
            if (
              date.getHours() % 2 == 0 ||
              date.getTime() == maxDate.getTime()
            ) {
              return d3.time.format('%H:%M')(new Date(d));
            }
            return '';
          });

        chart.yAxis
          .axisLabel('AMOUNT OF DATA')
          .axisLabelDistance(3)
          .tickFormat(function(d) {
            return humanFileSize(d, 2);
          });

        chart.yAxis.tickPadding(12);
        chart.xAxis.tickPadding(12);

        var chartData = [];
        if (data.is_android) {
          chartData.push({
            values: dataValues('background_total', data.points),
            key: 'Background data',
            color: '#A4A6B6',
            classed: 'dashed background-total',
            strokeWidth: 2
          });
          chartData.push({
            values: dataValues('foreground_total', data.points),
            key: 'Foreground data',
            color: '#1A1E40',
            classed: 'dashed foreground-total',
            strokeWidth: 2
          });
        }
        chartData.push({
          values: dataValues('total', data.points),
          key: 'Total data',
          color: '#1A1E40',
          classed: 'total-data',
          strokeWidth: 2.5
        });

        d3.select(this.chartTarget)
          .datum(chartData)
          .call(chart);

        nv.utils.windowResize(() => {
          chart.update();
        });
        return chart;
      },
      callback: () => {
        hideLoading(this.loadingIconTarget, this.chartTarget);
      }
    });
  }

  generateToolTip(chart, points, timezone) {
    chart.interactiveLayer.tooltip.contentGenerator((obj) => {
      let data = points[obj.index];
      if (!data) return '';
      let utc_time = moment.utc(data.timestamp * 1000).format('HH:mm');
      let home_time = moment
        .tz(data.timestamp * 1000, timezone)
        .format('HH:mm');
      return `
          <div class="has-tiny-text">
            <div class="is-display-flex has-strong-text has-uppercase-text has-tiny-margin-bottom">
              ${data.country}
            </div>
            <div class="is-display-flex has-space-between has-tiny-margin-bottom">
              <span class="has-uppercase-text">
                UTC
              </span>
              <span class="has-small-margin-left">
                ${utc_time}
              </span>
            </div>
            <div class="is-display-flex has-space-between has-tiny-margin-bottom">
              <span class="has-uppercase-text">
                HOME TIME ZONE
              </span>
              <span class="has-small-margin-left">
                ${home_time}
              </span>
            </div>
            <ul>
              ${this.renderToolTipTotals(data)}
            </ul>
          </div>
        `;
    });
  }

  renderToolTipTotals(data) {
    if (data.app_usage.length == 0) {
      return `
          <li class="is-display-flex has-space-between has-tiny-margin-bottom">
            <span>
              Total
            </span>
            <span class="has-small-margin-left">
              ${humanFileSize(data.total, 2)}
            </span>
          </li>
        `;
    }
    return data.app_usage
      .map(
        (dataItem) =>
          `
          <li class="is-display-flex has-space-between has-tiny-margin-bottom">
            <span>
              ${dataItem.name}
            </span>
            <span class="has-small-margin-left">
              ${humanFileSize(dataItem.total, 2)}
            </span>
          </li>
        `
      )
      .join('');
  }

  hideTooltip() {
    d3.selectAll('.nvtooltip').classed('is-hidden', true);
  }

  showTooltip() {
    d3.selectAll('.nvtooltip').classed('is-hidden', false);
  }

  deleteChart() {
    d3.selectAll('.nvd3-chart-daily > *').remove();
    d3.selectAll('.nv-interactive').remove();
  }

  get currentDate() {
    return moment(this.datepickerController.fp.selectedDates[0]).startOf('day');
  }

  get monthlyUsageController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'charts--monthly-usage'
    );
  }

  get datepickerController() {
    return this.application.getControllerForElementAndIdentifier(
      this.datepickerTarget,
      'forms--datepicker'
    );
  }
}
