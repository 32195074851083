export function showLoading(icon, container) {
  container.classList.add("is-invisible");
  icon.classList.remove("is-hidden");
  icon.classList.add("is-spinning");
}

export function hideLoading(icon, container) {
  container.classList.remove("is-invisible");
  icon.classList.add("is-hidden");
  icon.classList.remove("is-spinning");
}
