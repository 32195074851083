import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';
import { utcDateFormat, showLoading, hideLoading } from './helpers';

export default class extends Controller {
  static targets = [
    'listContainer',
    'list',
    'loadingIcon',
    'monthSelect',
    'title'
  ];

  initialize() {
    this.fetchData();
  }

  onChangeMonthSelect() {
    if (this.hasListTarget) {
      this.listContainerTarget.removeChild(this.listTarget);
    }
    this.fetchData();
  }

  private;

  fetchData() {
    showLoading(this.loadingIconTarget, this.listContainerTarget);
    let fetchData = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken()
      }
    };
    let url = `dashboard/data_plan_users.json?start_date=${this.startDate.format(
      utcDateFormat
    )}&end_date=${this.endDate.format(utcDateFormat)}`;
    fetch(url, fetchData)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error('Error:', error))
      .then((data) => {
        this.setTitle(data);
        this.generateList(data);
        hideLoading(this.loadingIconTarget, this.listContainerTarget);
      });
  }

  setTitle(data) {
    this.titleTarget.innerHTML = '';
    if (Object.values(data).length == 10) {
      this.titleTarget.insertAdjacentHTML('afterbegin', 10);
    }
  }

  generateList(data) {
    const list = `
        <ul class="decimal-list" data-target="charts--usage-percentage.list">
          ${this.renderListTemplate(data)}
        </ul>`;
    this.listContainerTarget.insertAdjacentHTML('afterbegin', list);
  }

  renderListTemplate(data) {
    return Object.values(data)
      .map((entry) => {
        return `
          <li class="has-extra-small-margin-bottom">
            <div class="is-display-flex has-space-between is-vertically-aligned-end">
              <div class="is-display-flex is-column-direction">
                <span class="has-capitalized-text has-medium-strong-text has-small-text">
                  ${entry.first_name} ${entry.last_name}
                </span>
                <a href="/devices/${entry.id}">
                  <span class="has-primary-text has-medium-strong-text">
                    ${entry.phone_number}
                  </span>
                </a>
              </div>
              <span class="has-strong-text">
                ${entry.percentage_data_used}%
              </span>
            </div>
            <div class="progress-bar is-secondary has-neutral-light-background is-thin">
              <div class="progress-bar-progress" style="width: ${
                entry.percentage_data_used
              }%"></div>
            </div>
          </li>`;
      })
      .join('');
  }

  get startDate() {
    return moment(this.monthSelectTarget.value).startOf('day');
  }

  get endDate() {
    return moment(this.startDate)
      .add(1, 'month')
      .subtract(1, 'day')
      .endOf('day');
  }
}
